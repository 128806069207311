import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Stack,
  InputGroup,
  Button,
} from "react-bootstrap";
import Image from "../../../commons/artefacts/elements/input/image";
import { renderImage } from "../../../commons/util/flightUtil";
import ExternalsManager from "../../../commons/externals/ExternalsManager";
import "../styles.css";
import FlightSearchForm from "./FlightSearchForm";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css/bundle";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Pagination, Navigation, Scrollbar, A11y } from "swiper";

import WOW from "wow.js";
import "animate.css/animate.min.css"; // Import animate.css styles
import "wow.js/css/libs/animate.css"; // Import wow.js styles
import { he } from "date-fns/locale";

SwiperCore.use([Autoplay]);

const Flights = () => {
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);

  const [activeTab, setActiveTab] = useState("one-way");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const swiperRef = useRef(null);

  useEffect(() => {
    const swiper = swiperRef?.current?.swiper;
    if (swiper) {
      swiper.autoplay.start(); // Start autoplay manually
    }
  }, []);

  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneNumberChange = (value, country) => {
    // value: the formatted phone number
    // country: the selected country code
    setPhoneNumber(value);
  };

  const [platform, setPlatform] = useState("");
  useEffect(() => {
    const currentPlatform = getPlatform();
    setPlatform(currentPlatform);
  }, []);

  const getPlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/manageheaderfooterwebview/.test(userAgent) && !window.MSStream) {
      return "mobileinapp";
    }
    return "web";
  };

  return (
    <>
      <div className="Flight-home-main-container">
        {/* <div
          className="wow fadeIn"
          data-wow-duration="2s"
          data-wow-delay="0.5s"
        >
          This will fade in with a delay
        </div> */}
        <div className="home-header wow fadeIn">
          <div className="container text-center head-txt">
            <h1>
              Book International and
              <br className="d-md-none" /> Domestic Flight
            </h1>
            <h2>Discover the real value of travel</h2>
          </div>
        </div>

        <Container fluid="xl" className="Flight-container-xl">
          <Row className="gx-xl-5 Flight-row align-items-center">
            <Col xl="10" className="mx-auto">
              <div className="Flight-widgetSection position-relative pb-0">
                {/* <ul className="d-flex trip-type justify-content-start mb-3 ps-0 gap-2">
                  <li
                    className={`px-md-4 px-3 py-2 Flight-cursor-pointer ${
                      activeTab === "one-way" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("one-way")}
                  >
                    One Way
                  </li>
                  <li
                    className={`px-md-4 px-3 py-2 Flight-cursor-pointer ${
                      activeTab === "round-trip" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("round-trip")}
                  >
                    Round Trip
                  </li>
                </ul>*/}

                <FlightSearchForm />
              </div>
            </Col>
          </Row>
          {platform === "mobileinapp" ? null : (
            <div>
              <div className="sec-pad">
                <div className="Flight-box-stretch static-features">
                  <div className="feature1 d-flex align-items-center wow fadeIn">
                    <Stack direction="horizontal" gap={3}>
                      <Image
                        alt="Lowest Budget Airline"
                        src={renderImage("lowest-budget-icon.svg")}
                      />
                      <div className="text-white">
                        <h2 className="Flight-font14 Flight-font-semibold mb-0">
                          Lowest Budget Airline
                        </h2>
                        <h3 className="Flight-font20 Flight-font-semibold mb-0">
                          Fare Guarantee
                        </h3>
                      </div>
                    </Stack>
                  </div>
                  <div className="feature1 d-flex align-items-center wow fadeIn">
                    <Stack direction="horizontal" gap={3}>
                      <Image
                        alt="Lowest Budget Airline"
                        src={renderImage("easy-booking-icon.svg")}
                      />
                      <div className="text-white">
                        <h2 className="Flight-font14 Flight-font-semibold mb-0">
                          Easy Book In
                        </h2>
                        <h3 className="Flight-font20 Flight-font-semibold mb-0">
                          Just 3 Steps
                        </h3>
                      </div>
                    </Stack>
                  </div>
                  <div className="feature1 d-flex align-items-center wow fadeIn">
                    <Stack direction="horizontal" gap={3}>
                      <Image
                        alt="Lowest Budget Airline"
                        src={renderImage("support-icon.svg")}
                      />
                      <div className="text-white">
                        <h2 className="Flight-font14 Flight-font-semibold mb-0">
                          24x365 Days
                        </h2>
                        <h3 className="Flight-font20 Flight-font-semibold mb-0">
                          24*7 Support
                        </h3>
                      </div>
                    </Stack>
                  </div>
                </div>
              </div>

              <div className="sec-pad">
                <div className="Flight-box-stretch div4 text-center">
                  <div className="wow fadeInUp">
                    <Image
                      alt="Lowest Budget Airline"
                      src={renderImage("feature-icon1.svg")}
                    />
                    <h2 className="Flight-font20 Flight-font-semibold mt-2">
                      Look No Further
                    </h2>
                    <h3 className="Flight-font14">
                      Search all travel deals, in one go
                    </h3>
                  </div>
                  <div className="wow fadeInUp" data-wow-delay="0.2s">
                    <Image
                      alt="Lowest Budget Airline"
                      src={renderImage("feature-icon2.svg")}
                    />
                    <h2 className="Flight-font20 Flight-font-semibold mt-2">
                      Book With Confidence
                    </h2>
                    <h3 className="Flight-font14">
                      No hidden fees, taxes orother nasty surprises
                    </h3>
                  </div>
                  <div className="wow fadeInUp" data-wow-delay="0.4s">
                    <Image
                      alt="Lowest Budget Airline"
                      src={renderImage("feature-icon3.svg")}
                    />
                    <h2 className="Flight-font20 Flight-font-semibold mt-2">
                      Pay The Way You Want
                    </h2>
                    <h3 className="Flight-font14">
                      See only sellers who support your preferred method
                    </h3>
                  </div>
                  <div className="wow fadeInUp" data-wow-delay="0.6s">
                    <Image
                      alt="Lowest Budget Airline"
                      src={renderImage("feature-icon4.svg")}
                    />
                    <h2 className="Flight-font20 Flight-font-semibold mt-2">
                      Instant Booking
                    </h2>
                    <h3 className="Flight-font14">
                      For selected sellers, book with just a couple of clicks
                    </h3>
                  </div>
                </div>
              </div>

              {/* <div className="sec-pad">
            <div className="pb-md-4 pb-3">
              <h2 className="main-heading2 mb-3 neulist-sb-italic">
                Promotions & Offers
              </h2>
              <div className=" position-relative">
                <Swiper
                  slidesPerView={2}
                  spaceBetween={10}
                  navigation={false}
                  autoplay={true}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                  }}
                  modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
                  className="mySwiper feature-carousel"
                >
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("promotion-1.jpg")}
                        alt=""
                      />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("promotion-2.jpg")}
                        alt=""
                      />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("promotion-3.jpg")}
                        alt=""
                      />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("promotion-4.jpg")}
                        alt=""
                      />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("promotion-1.jpg")}
                        alt=""
                      />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("promotion-2.jpg")}
                        alt=""
                      />
                    </a>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div> */}

              <div className="sec-pad">
                <h2 className="main-heading2  neulist-sb-italic">
                  Our Partners
                </h2>
                <Swiper
                  ref={swiperRef}
                  speed={5000}
                  slidesPerView={8}
                  spaceBetween={10}
                  loop={true}
                  autoplay={{
                    delay: 0,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    300: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 5,
                      spaceBetween: 10,
                    },
                  }}
                  modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
                  className="mySwiper destination-carousel partnersldier"
                >
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={160}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/axis.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={110}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/fleek.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={80}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/firstcry.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={140}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/flipkart.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={160}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/kreditbee.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={170}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/timespoints.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={90}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/grabOn.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={30}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/Fi.png")}
                      />
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={70}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/slice.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={110}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/myntra.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={150}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/ICICI.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={110}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/HDFCsmartbuy.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={80}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/jupiter.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={160}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/freecharge.svg")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={160}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/kotakmahindra.svg")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={70}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/slice.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={160}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/axis.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={110}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/fleek.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={80}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/firstcry.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={140}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/flipkart.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={160}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/kreditbee.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={170}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/timespoints.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={90}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/grabOn.png")}
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partner-inner">
                      <Image
                        width={30}
                        alt="Lowest Budget Airline"
                        src={renderImage("partners-logo/Fi.png")}
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>

              <div className="sec-pad">
                <h2 className="main-heading2 neulist-sb-italic">
                  Top Flight Routes...
                </h2>
                <Swiper
                  ref={swiperRef}
                  slidesPerView={1.5}
                  spaceBetween={10}
                  autoplay={{
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    500: {
                      slidesPerView: 2.2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    1200: {
                      slidesPerView: 5,
                      spaceBetween: 20,
                    },
                  }}
                  modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
                  className="mySwiper destination-carousel rounded-carusel"
                >
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("country-1.jpg")}
                        alt=""
                      />
                      <h4>
                        Chennai
                        <Image
                          className="mx-2"
                          src={renderImage("tck_plane.svg")}
                          alt=""
                        />
                        Mumbai
                      </h4>
                      <h3 className="Flight-theme-txt Flight-font-bold mt-1">
                        MAA-BOM
                      </h3>
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("country-2.jpg")}
                        alt=""
                      />
                      <h4>
                        Delhi
                        <Image
                          className="mx-2"
                          src={renderImage("tck_plane.svg")}
                          alt=""
                        />
                        Ahmedabad
                      </h4>
                      <h3 className="Flight-theme-txt Flight-font-bold mt-1">
                        DEL-AMD
                      </h3>
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("country-3.jpg")}
                        alt=""
                      />
                      <h4>
                        Delhi
                        <Image
                          className="mx-2"
                          src={renderImage("tck_plane.svg")}
                          alt=""
                        />
                        Lucknow
                      </h4>
                      <h3 className="Flight-theme-txt Flight-font-bold mt-1">
                        DEL-LKO
                      </h3>
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("country-4.jpg")}
                        alt=""
                      />
                      <h4>
                        Mumbai
                        <Image
                          className="mx-2"
                          src={renderImage("tck_plane.svg")}
                          alt=""
                        />
                        Chennai
                      </h4>
                      <h3 className="Flight-theme-txt Flight-font-bold mt-1">
                        BOM-MAA
                      </h3>
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("country-5.jpg")}
                        alt=""
                      />
                      <h4>
                        Mumbai
                        <Image
                          className="mx-2"
                          src={renderImage("tck_plane.svg")}
                          alt=""
                        />
                        Dubai
                      </h4>
                      <h3 className="Flight-theme-txt Flight-font-bold mt-1">
                        BOM-DXB
                      </h3>
                    </a>
                  </SwiperSlide>

                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("country-6.jpg")}
                        alt=""
                      />
                      <h4>
                        Mumbai
                        <Image
                          className="mx-2"
                          src={renderImage("tck_plane.svg")}
                          alt=""
                        />
                        Kolkata
                      </h4>
                      <h3 className="Flight-theme-txt Flight-font-bold mt-1">
                        BOM-CCU
                      </h3>
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("country-7.jpg")}
                        alt=""
                      />
                      <h4>
                        Hyderabad
                        <Image
                          className="mx-2"
                          src={renderImage("tck_plane.svg")}
                          alt=""
                        />
                        Bangalore
                      </h4>
                      <h3 className="Flight-theme-txt Flight-font-bold mt-1">
                        HYD-BLR
                      </h3>
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("country-8.jpg")}
                        alt=""
                      />
                      <h4>
                        Mumbai
                        <Image
                          className="mx-2"
                          src={renderImage("tck_plane.svg")}
                          alt=""
                        />
                        Jaipur
                      </h4>
                      <h3 className="Flight-theme-txt Flight-font-bold mt-1">
                        BOM-JAI
                      </h3>
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("country-5.jpg")}
                        alt=""
                      />
                      <h4>
                        Delhi
                        <Image
                          className="mx-2"
                          src={renderImage("tck_plane.svg")}
                          alt=""
                        />
                        Dubai
                      </h4>
                      <h3 className="Flight-theme-txt Flight-font-bold mt-1">
                        DEL-DXB
                      </h3>
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="">
                      <Image
                        className="w-100"
                        src={renderImage("country-5.jpg")}
                        alt=""
                      />
                      <h4>
                        Delhi
                        <Image
                          className="mx-2"
                          src={renderImage("tck_plane.svg")}
                          alt=""
                        />
                        Dubai
                      </h4>
                      <h3 className="Flight-theme-txt Flight-font-bold mt-1">
                        DEL-DXB
                      </h3>
                    </a>
                  </SwiperSlide>
                </Swiper>
              </div>

              {/* <div className="sec-pad pb-4 beast-deal-wrap">
            <h2 className="main-heading2 neulist-sb-italic">Best Deals</h2>
            <Swiper
              ref={swiperRef}
              slidesPerView={5}
              spaceBetween={10}
              autoplay={false}
              // autoplay={{
              //   disableOnInteraction: false,
              // }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                300: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                500: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 7,
                  spaceBetween: 12,
                },
              }}
              modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
              className="mySwiper destination-carousel bestdeal-carousel"
            >
              <SwiperSlide>
                <div className="bestdeal-box">
                  <div className="Flight-font16 Flight-font-bold">
                    <div className="city-drop1 position-relative">Chennai</div>
                    <div className="city-drop2 position-relative mt-3">
                      Mumbai
                    </div>
                  </div>
                  <div className="text-secondary Flight-font13 Flight-font-semibold mt-2">
                    Starting from
                  </div>
                  <div className="Flight-theme-txt Flight-font18 Flight-font-bold">
                    ₹4,059
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bestdeal-box">
                  <div className="Flight-font16 Flight-font-bold">
                    <div className="city-drop1 position-relative">Delhi</div>
                    <div className="city-drop2 position-relative mt-3">
                      Ahmedabad
                    </div>
                  </div>
                  <div className="text-secondary Flight-font13 Flight-font-semibold mt-2">
                    Starting from
                  </div>
                  <div className="Flight-theme-txt Flight-font18 Flight-font-bold">
                    ₹4,059
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bestdeal-box">
                  <div className="Flight-font16 Flight-font-bold">
                    <div className="city-drop1 position-relative">Delhi</div>
                    <div className="city-drop2 position-relative mt-3">
                      Lucknow
                    </div>
                  </div>
                  <div className="text-secondary Flight-font13 Flight-font-semibold mt-2">
                    Starting from
                  </div>
                  <div className="Flight-theme-txt Flight-font18 Flight-font-bold">
                    ₹4,059
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bestdeal-box">
                  <div className="Flight-font16 Flight-font-bold">
                    <div className="city-drop1 position-relative">Mumbai</div>
                    <div className="city-drop2 position-relative mt-3">
                      Chennai
                    </div>
                  </div>
                  <div className="text-secondary Flight-font13 Flight-font-semibold mt-2">
                    Starting from
                  </div>
                  <div className="Flight-theme-txt Flight-font18 Flight-font-bold">
                    ₹4,059
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bestdeal-box">
                  <div className="Flight-font16 Flight-font-bold">
                    <div className="city-drop1 position-relative">Mumbai</div>
                    <div className="city-drop2 position-relative mt-3">
                      Kolkata
                    </div>
                  </div>
                  <div className="text-secondary Flight-font13 Flight-font-semibold mt-2">
                    Starting from
                  </div>
                  <div className="Flight-theme-txt Flight-font18 Flight-font-bold">
                    ₹4,059
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bestdeal-box">
                  <div className="Flight-font16 Flight-font-bold">
                    <div className="city-drop1 position-relative">
                      Hyderabad
                    </div>
                    <div className="city-drop2 position-relative mt-3">
                      Bangalore
                    </div>
                  </div>
                  <div className="text-secondary Flight-font13 Flight-font-semibold mt-2">
                    Starting from
                  </div>
                  <div className="Flight-theme-txt Flight-font18 Flight-font-bold">
                    ₹4,059
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bestdeal-box">
                  <div className="Flight-font16 Flight-font-bold">
                    <div className="city-drop1 position-relative">Delhi</div>
                    <div className="city-drop2 position-relative mt-3">
                      Dubai
                    </div>
                  </div>
                  <div className="text-secondary Flight-font13 Flight-font-semibold mt-2">
                    Starting from
                  </div>
                  <div className="Flight-theme-txt Flight-font18 Flight-font-bold">
                    ₹4,059
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bestdeal-box">
                  <div className="Flight-font16 Flight-font-bold">
                    <div className="city-drop1 position-relative">Chennai</div>
                    <div className="city-drop2 position-relative mt-3">
                      Mumbai
                    </div>
                  </div>
                  <div className="text-secondary Flight-font13 Flight-font-semibold mt-2">
                    Starting from
                  </div>
                  <div className="Flight-theme-txt Flight-font18 Flight-font-bold">
                    ₹4,059
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bestdeal-box">
                  <div className="Flight-font16 Flight-font-bold">
                    <div className="city-drop1 position-relative">Delhi</div>
                    <div className="city-drop2 position-relative mt-3">
                      Ahmedabad
                    </div>
                  </div>
                  <div className="text-secondary Flight-font13 Flight-font-semibold mt-2">
                    Starting from
                  </div>
                  <div className="Flight-theme-txt Flight-font18 Flight-font-bold">
                    ₹4,059
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bestdeal-box">
                  <div className="Flight-font16 Flight-font-bold">
                    <div className="city-drop1 position-relative">Delhi</div>
                    <div className="city-drop2 position-relative mt-3">
                      Lucknow
                    </div>
                  </div>
                  <div className="text-secondary Flight-font13 Flight-font-semibold mt-2">
                    Starting from
                  </div>
                  <div className="Flight-theme-txt Flight-font18 Flight-font-bold">
                    ₹4,059
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div> */}

              <div className="sec-pad pt-0">
                <div className="download-block p-md-5 py-4 px-3">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6 col-md-7 me-lg-auto pe-lg-5 text-md-start text-center text-white wow fadeIn Flight-font-semibold Flight-font16">
                      <h2 className="main-heading1 mb-1 neulist-sb-italic">
                        Download App Now !
                      </h2>
                      <div className="neu-light pb-md-2">
                        Get the Ogenie Mobile App and Save More!
                      </div>
                      {/* <div className="form-group Flight-telpicker  my-3 ">
                    <InputGroup className=" getapp">
                      <div className="PhoneInputInput">
                        <PhoneInput
                          className="c-code-control"
                          country={"in"} // Initial country selection
                          value={phoneNumber}
                          placeholder="Enter mobile number"
                          onChange={handlePhoneNumberChange}
                        />
                      </div>
                      <InputGroup.Text
                        className="ms-auto p-0"
                        id="basic-addon2"
                      >
                        <button
                          type="button"
                          className="btn new-letter-btn Flight-font14 Flight-font-semibold"
                        >
                          Get App Link
                        </button>
                      </InputGroup.Text>
                    </InputGroup>
                  </div> */}

                      <div className="text-uppercase mb-3 mt-4">
                        IT’S THE EASIEST WAY TO MANAGE YOUR BOOKING
                      </div>
                      <div className="d-flex align-items-center justify-content-md-start justify-content-center">
                        <div className="d-flex flex-md-column me-md-3">
                          <a href="https://play.google.com/store/apps/details?id=com.ogenie.app&pli=1">
                            <Image
                              className="mb-md-1 me-2 me-md-0 dload-btn"
                              src={renderImage("google-play.svg")}
                              alt=""
                            />
                          </a>
                          <a href="https://apps.apple.com/in/app/ogenie/id1460563270">
                            <Image
                              className="mt-md-1 dload-btn"
                              src={renderImage("app-store.svg")}
                            />
                          </a>
                        </div>
                        <div className="ms-2 d-md-block d-none">
                          <Image src={renderImage("our-qr.png")} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 d-lg-block d-none  wow fadeIn">
                      <Image
                        className="w-100 mt-md-0 mt-3"
                        src={renderImage("mobile-screen.png")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="sec-pad">
                <div className="guideline">
                  <div className=" row align-items-lg-center justify-content-md-between justify-content-end flex-wrap">
                    <div className="col-lg-10">
                      <div className="d-flex align-items-xl-center align-items-start gap-3 ">
                        <Image
                          className=""
                          src={renderImage("info.svg")}
                          width={36}
                        />
                        <h6 className="Flight-font18 mb-0 text-white">
                          Read the latest domestic & International travel
                          guidelines here before you travel. Stay safe, and
                          travel safe.
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="mt-md-0 mt-2 text-end">
                        <a
                          target="_blank"
                          className="text-white text-decoration-none Flight-font18"
                          href="https://www.civilaviation.gov.in/sites/default/files/State_wise_quarantine_regulation-converted.pdf"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sec-pad wow fadeIn">
                <h2 className="main-heading2 neulist-sb-italic">
                  About Ogenietravels
                </h2>
                <p className="Flight-font14 mb-0">
                  Ogenietravels introduces travellers to the best online flight
                  booking experience there is. Every day, thousands of
                  businesspersons and tourists book domestic and international
                  flight tickets through our various platforms. Unbelievably
                  cheap flights and a seamless air ticket booking process make
                  travels merrier than ever. With Ogenietravels, you can book
                  flight tickets with sought-after Indian airlines such as
                  IndiGo, Vistara, Go First, Air India and SpiceJet, as well as
                  renowned international air passenger carriers like Thai
                  Airways, Singapore Airlines and Emirates. OGenie Travels
                  strives to make travel dreams come true for everyone. If you
                  have got an upcoming travel plan, access the latest offers
                  from Ogenietravels and take your travel game up a notch.
                </p>
              </div>
              <div className="sec-pad wow fadeIn" data-wow-delay="0.2s">
                <h2 className="main-heading2 neulist-sb-italic">
                  Why Book Air Tickets on Ogenietravels?
                </h2>
                <p className="Flight-font14 mb-0">
                  Flight bookings can be an expensive affair, especially when
                  you don't have the right partner to book your air tickets.
                  Ogenietravels offers the biggest deals on flights, making it
                  possible to travel domestic and international without spending
                  a fortune. Sign up on the Ogenietravels website or mobile app
                  to unlock new user discounts and many other offers. Also, get
                  notified about the latest coupons and offers through the
                  mobile app that helps you plan a trip on the go. We guarantee
                  you the lowest airfares for domestic air tickets. If you can
                  find a better deal anywhere else, then we will pay you triple
                  the airfare difference. With our free flight cancellation and
                  rescheduling options, you can also bid adieu to your worries
                  of travel plan change. So, stop looking anywhere else and
                  seize the hand-picked offers on flight tickets to make your
                  travel economical.
                </p>
              </div>
              <div className="sec-pad wow fadeIn" data-wow-delay="0.4s">
                <h2 className="main-heading2 neulist-sb-italic">
                  How to Book Cheap Flight Tickets on Ogenietravels?
                </h2>
                <p className="Flight-font14">
                  Ogenietravels has continuously innovated ways to cut down the
                  efforts needed in air ticket booking. From a user-friendly
                  interface and easy flight comparisons to quick payments and
                  instant bookings, one can have a stress-free experience while
                  booking domestic or international flights. The launch of our
                  flawless travel app was another step to add to the convenience
                  of the modern-day traveller. It is perfect for people who are
                  always on the move. Search, compare, book, and manage your air
                  ticket bookings without any hassles with the best travel app
                  in India. You can download it now on your iOS or Android
                  device.
                </p>
                <p className="Flight-font14">
                  Steps to make a flight booking on the Ogenietravels app:
                </p>
                <ul className="ls-circle Flight-font14 mb-0  text-capitalize">
                  <li>Open the app and click on 'Flights'.</li>
                  <li>Enter route, date of travel and other details.</li>
                  <li>Click on 'Search'.</li>
                  <li>Select flight and the best fare option.</li>
                  {/* <li>Select/enter coupon code.</li> */}
                  <li>Insert traveller details and complete the booking.</li>
                </ul>
              </div>
              <div className="sec-pad wow fadeIn" data-wow-delay="0.6s">
                <h2 className="main-heading2 neulist-sb-italic">
                  FAQs - Flights
                </h2>
                <p className="mb-0 Flight-font16 Flight-font-semibold text-capitalize">
                  Where can I find the latest flight booking offers?
                </p>
                <p className="Flight-font14">
                  You can check out the latest flight deals and discounts on the
                  offer page. You can also choose an offer on the booking page
                  before paying for your flight tickets.
                </p>

                <p className="mb-0 Flight-font16 Flight-font-semibold text-capitalize">
                  What is the best flight offer on Ogenietravels?
                </p>
                <p className="Flight-font14">
                  If you have not made a flight booking on Ogenietravels before,
                  then you can use the New User Offer to avail exclusive
                  discount on your booking.
                </p>

                <p className="mb-0 Flight-font16 Flight-font-semibold text-capitalize">
                  Which airlines' flight tickets can I book on Ogenietravels?
                </p>
                <p className="Flight-font14">
                  You can book IndiGo, Vistara, Air India, Go First, Air Deccan
                  and SpiceJet flight tickets on Ogenietravels. You can also fly
                  with international airlines like Emirates, Qatar Airways,
                  Etihad and more.
                </p>

                <p className="mb-0 Flight-font16 Flight-font-semibold text-capitalize">
                  What are the best and worst days to fly?
                </p>
                <p className="Flight-font14 mb-0">
                  Although there is no rule of thumb, Tuesdays and Wednesdays
                  are believed to be the cheapest days to fly, while the weekend
                  is believed to be the most expensive. So, plan your trip
                  accordingly to save money.
                </p>
              </div>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default Flights;
