import React, { useContext, useEffect, useState } from "react";
import Image from "../../../commons/artefacts/elements/input/image";
import { Container, Row, Col, Nav, Tab, Tabs } from "react-bootstrap";
import { renderImage } from "../../../commons/util/flightUtil";
import Profile from "./Profile";
import Transactions from "./Transactions";
import Bookings from "./Bookings";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DataContext } from "../../../commons/context/dataContext";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "../../../commons/util/firebase/firebase";

const auth = getAuth(firebase);

const Dashboard = () => {
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  const [loader, setLoader] = useState(false);
  const [userObj, setUserObj] = useState({});
  const { setUID } = useContext(DataContext);
  useEffect(() => {
    if (loading) setLoader(true);
    else if (!user || error) {
      history.push("/");
    } else {
      setLoader(false);
      setUserObj(user);
      setUID(user);
    }
    return null;
  }, [user, loading, error]);

  const [activeTab, setActiveTab] = useState("Upcoming"); // Default active tab

  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };
  return (
    <div>
      <Container fluid="xxl" className="pt-3 px-md-3 px-0">
        <Tab.Container id="left-tabs-example" defaultActiveKey="second">
          <Row className="gx-md-3 gx-0">
            <Col md="3">
              <div className="dashboard-sidebar">
                <div className="twoborder border-top-0 mx-2 py-md-4 py-3 d-md-block d-none">
                  <div className="profile-image text-center mb-2">
                    <Image
                      className="rounded-pill"
                      src={renderImage("avatar1.svg")}
                    />
                  </div>
                  <div className="profile-detail text-center">
                    <h6 className="Flight-font14 Flight-semibold mb-0">
                      {user?.phoneNumber}{" "}
                      <Image src={renderImage("verified-user.svg")} />
                    </h6>
                  </div>
                </div>
                <div className="twoborder border-bottom-0 mx-2 py-md-2 py-0">
                  {" "}
                </div>
                <Nav
                  variant="pills"
                  className="flex-md-column ms-md-2 ms-3 db-nav"
                >
                  {/* <Nav.Item>
                    <Nav.Link eventKey="first">
                      <Image
                        className="me-md-1"
                        src={renderImage("profile-icn.svg")}
                      />{" "}
                      Profile
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      <Image
                        className="me-md-1"
                        src={renderImage("booking-icn.svg")}
                      />{" "}
                      Bookings
                    </Nav.Link>
                  </Nav.Item>

                  {/*<Nav.Item>
                    <Nav.Link eventKey="third">
                      <Image
                        className="me-md-1"
                        src={renderImage("transaction-icn.svg")}
                      />{" "}
                      Transaction
                    </Nav.Link>
                    </Nav.Item>*/}
                </Nav>
              </div>
            </Col>
            <Col md="9">
              <Tab.Content className="db-booking">
                <Tab.Pane eventKey="first">
                  <Profile />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  {userObj?.uid ? (
                    <Tabs
                      defaultActiveKey="Upcoming"
                      id="uncontrolled-tab-example"
                      activeKey={activeTab}
                      onSelect={handleTabSelect} // Handle tab selection
                    >
                      <Tab eventKey="Upcoming" title="Upcoming">
                        {activeTab === "Upcoming" ? (
                          <Bookings filter={"active"} />
                        ) : null}
                      </Tab>
                      <Tab eventKey="Past" title="Past">
                        {activeTab === "Past" ? (
                          <Bookings filter={"past"} />
                        ) : null}
                      </Tab>
                      <Tab eventKey="Cancelled" title="Cancelled">
                        {activeTab === "Cancelled" ? (
                          <Bookings filter={"cancelled"} />
                        ) : null}
                      </Tab>
                    </Tabs>
                  ) : null}
                </Tab.Pane>

                <Tab.Pane eventKey="third">
                  <Transactions />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default Dashboard;
