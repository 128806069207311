import ExternalsManager from "../../commons/externals/ExternalsManager";
import {
  GET_AIRPORT_DATA,
  GET_COUNTRY_CODE,
  GET_FARE_N_CLASS,
  GET_FARE_RULE,
  GET_SEARCH_FLIGHT,
} from "../../constants/endpoints";
import { getDateByDDMMYYYYFormate, getDateByYYYYMMDDFormate } from "../../commons/util/dateTimeUtil";

const getAirportData = async (val = "") => {
  let payload = { prefix: val };
  let response = await ExternalsManager?.API.post(GET_AIRPORT_DATA, payload);
  if (response?.type === "failure") {
    window.alert(
      `Status Code: ${response.status}\nMessage: ${response.message}`
    );
  } else {
    return response?.data;
  }
};

const getFareRuleByPriceId = async (id) => {
  return ExternalsManager?.API.post(GET_FARE_RULE, {
    id,
    flowType: "SEARCH",
  });
};

const getFlightClassData = async () => {
  let payload = { type: "cc" };
  let response = await ExternalsManager?.API.post(GET_FARE_N_CLASS, payload);
  if (response?.type === "failure") {
    window.alert(
      `Status Code: ${response.status}\nMessage: ${response.message}`
    );
  } else {
    return response?.data;
  }
};

const getFareTypeData = async () => {
  let payload = { type: "pft" };
  let response = await ExternalsManager?.API.post(GET_FARE_N_CLASS, payload);
  if (response?.type === "failure") {
    window.alert(
      `Status Code: ${response.status}\nMessage: ${response.message}`
    );
  } else {
    return response?.data;
  }
};

const getCountryCode = async () => {
  let payload = {};
  let response = await ExternalsManager?.API.post(GET_COUNTRY_CODE, payload);
  if (response?.type === "failure") {
    window.alert(
      `Status Code: ${response.status}\nMessage: ${response.message}`
    );
  } else {
    return response?.data;
  }
};

const getFlightData = async (
  depCode,
  arrCode,
  passengers,
  fareType,
  flightClass,
  startDate,
  endDate = null
) => {
  let routeInfos = [
    {
      fromCityOrAirport: {
        code: depCode,
      },
      toCityOrAirport: {
        code: arrCode,
      },
      travelDate:getDateByDDMMYYYYFormate(startDate),
    },
  ];

  if (endDate) {
    routeInfos.push({
      fromCityOrAirport: {
        code: arrCode,
      },
      toCityOrAirport: {
        code: depCode,
      },
      travelDate: getDateByDDMMYYYYFormate(endDate),
    });
  }

  const payload = {
    searchQuery: {
      cabinClass: flightClass,
      paxInfo: {
        ADULT: passengers.adult,
        CHILD: passengers.child,
        INFANT: passengers.infant,
      },
      routeInfos,
      searchModifiers: {
        pft: fareType,
        isDirectFlight: true,
        isConnectingFlight: true,
      },
    },
  };

  return ExternalsManager.API.post(GET_SEARCH_FLIGHT, payload);
};

export {
  getAirportData,
  getFareTypeData,
  getFlightClassData,
  getCountryCode,
  getFlightData,
  getFareRuleByPriceId,
};
