import React, { useState, useEffect } from "react";
import { Dropdown, Modal, Container, Stack, Button } from "react-bootstrap";
import Image from "../../../commons/artefacts/elements/input/image";
import { renderImage } from "../../../commons/util/flightUtil";
import LoginModal from "../Auth/LoginModal";
import { getAuth, signOut } from "firebase/auth";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import firebase from "../../../commons/util/firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
const auth = getAuth(firebase);

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    className="profile-dd dropdown-toggle rounded-pill d-flex align-items-center px-2"
    href="/"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const Header = (props) => {
  const [platform, setPlatform] = useState("");
  useEffect(() => {
    const currentPlatform = getPlatform();
    setPlatform(currentPlatform);
  }, []);

  const getPlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/manageheaderfooterwebview/.test(userAgent) && !window.MSStream) {
      return "mobileinapp";
    }
    return "web";
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const history = useHistory();
  const [user] = useAuthState(auth);
  const [showLogin, setShowLogin] = useState(false);

  const handleLoginModalClose = () => setShowLogin(false);
  const handleLogin = () => setShowLogin(true);
  const handleLogout = () => {
    setOpen(false);
    const auth = getAuth(firebase);
    signOut(auth)
      .then(() => {
        history.push("/");

        // Add any additional logic you need after signing out
      })
      .catch((error) => {
        console.error("Error signing out:", error);
        // Handle any errors that occur during signout
      });
  };
  const handleBookings = () => {
    console.log(history);
    history.push("/dashboard");
  };

  return (
    <>
      <header>
        {platform === "web" ? null : (
          <Container fluid="xxl" className="text-center py-2">
            <a href="/">
              <Image alt="" src={renderImage("beta-logo.svg")} />
            </a>
          </Container>
        )}
        {platform === "mobileinapp" ? null : (
          <Container
            fluid="xxl"
            className="justify-content-between d-flex align-items-center headerwrapper navOnly"
          >
            <a href="/">
              <Image alt="" src={renderImage("beta-logo.svg")} />
            </a>
            {/* <nav className="d-none">
            <ul className="d-flex w-100 justify-content-center">
              <li>
                <a
                  href="/"
                  className="d-flex align-items-center flex-column active"
                >
                  <span>
                    <Image src={renderImage("flight-home-icon.svg")} />
                  </span>
                  <span className="mallory-mediuam font13 mt-1">Flight</span>
                </a>
              </li>
              <li>
                <a href="/" className="d-flex align-items-center flex-column">
                  <span>
                    <Image src={renderImage("hotel-home-icon.svg")} />
                  </span>
                  <span className="mallory-mediuam font13 mt-1">Hotels</span>
                </a>
              </li>
              <li>
                <a href="/" className="d-flex align-items-center flex-column">
                  <span>
                    <Image src={renderImage("chartered-home-icon.svg")} />
                  </span>
                  <span className="mallory-mediuam font13 mt-1">
                    Chartered Flight
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="d-flex align-items-center flex-column more-menu"
                  href="/"
                >
                  <span>
                    <Image src={renderImage("more-icon.svg")} />
                  </span>
                  <span className="mallory-mediuam font13 mt-1">More</span>
                </a>
                <div className="more-menu-op">
                  <ul className="d-flex">
                    <li>
                      <a
                        href="/"
                        className="d-flex align-items-center flex-column c-soon"
                      >
                        <span>
                          <Image src={renderImage("coming-soon.svg")} />
                        </span>
                        <span className="mallory-mediuam font13 mt-1">Cab</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="d-flex align-items-center flex-column"
                      >
                        <span>
                          <Image
                            src={renderImage("membership-home-icon.svg")}
                          />
                        </span>
                        <span className="mallory-mediuam font13 mt-1">
                          Membership
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a
                  href="/"
                  className="d-flex align-items-center flex-column c-soon"
                >
                  <span>
                    <Image src={renderImage("coming-soon.svg")} />
                  </span>
                  <span className="mallory-mediuam font13 mt-1">Cab</span>
                </a>
              </li>
              <li>
                <a href="/" className="d-flex align-items-center flex-column">
                  <span>
                    <Image src={renderImage("membership-home-icon.svg")} />
                  </span>
                  <span className="mallory-mediuam font13 mt-1">
                    Membership
                  </span>
                </a>
              </li>
            </ul>
          </nav> */}

            <Dropdown align="end" className="header-right">
              <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                <Image
                  className="thumbnail rounded-circle"
                  src={renderImage("thumbnail.svg")}
                  alt=""
                />
                {user?.uid ? user?.phoneNumber : "Login"}
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-0">
                {user?.uid ? (
                  <>
                    <Dropdown.Item
                      href="/dashboard"
                      className="Flight-font14 Flight-font-bold border-bottom"
                    >
                      My Account
                    </Dropdown.Item>

                    <Dropdown.Item
                      className="Flight-font14 Flight-font-bold"
                      onClick={handleLogout}
                    >
                      Logout
                    </Dropdown.Item>
                  </>
                ) : (
                  <Dropdown.Item
                    className="Flight-font14 Flight-font-bold"
                    // onClick={handleClickOpen}
                    onClick={handleLogin}
                  >
                    Login
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Container>
        )}
      </header>

      {platform === "web" ? null : (
        <div className="mobile-navigation px-4 text-center">
          <Stack
            direction="horizontal"
            gap={3}
            className="justify-content-between"
          >
            <a href="" className="text-dark text-decoration-none">
              <Image alt="home" src={renderImage("homeicon.svg")} />
              {/* <div className="Flight-font12 mt-1">Home</div> */}
            </a>
            {user?.uid ? (
              <a href="/dashboard" className="text-dark text-decoration-none">
                <Image alt="home" src={renderImage("trip.svg")} />
                {/* <div className="Flight-font12 mt-1">Trips</div> */}
              </a>
            ) : (
              <a
                className="Flight-font14 Flight-font-bold"
                onClick={handleLogin}
              >
                <Image alt="home" src={renderImage("trip.svg")} />
              </a>
            )}
            {user?.uid ? (
              <a
                onClick={handleClickOpen}
                className="text-dark text-decoration-none"
              >
                <Image alt="home" src={renderImage("account.svg")} />
                {/* <div className="Flight-font12 mt-1">Account</div> */}
              </a>
            ) : (
              <a
                className="Flight-font14 Flight-font-bold"
                onClick={handleLogin}
              >
                <Image alt="home" src={renderImage("account.svg")} />
              </a>
            )}
          </Stack>
        </div>
      )}

      <Modal
        centered
        show={showLogin}
        onHide={handleLoginModalClose}
        size="md"
        dialogClassName="modal-400w"
      >
        <Modal.Header
          className="justify-content-end border-bottom-0 pb-0"
          closeButton
        ></Modal.Header>
        <Modal.Body className="px-md-5 px-4">
          <LoginModal handleLoginModalClose={handleLoginModalClose} />
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={open}
        onHide={handleClose}
        size="md"
        dialogClassName="modal-400w"
      >
        <Modal.Body className="p-4 text-center">
          <div className="Flight-font-semibold pb-3 pt-3">
            Are you Sure,
            <br />
            you want to Logout
          </div>
          <Stack
            className="justify-content-center mt-4 pb-4"
            gap={3}
            direction="horizontal"
          >
            <Button onClick={handleLogout} className="Flight-btn-purple px-4">
              Logout
            </Button>
            <Button
              onClick={handleClose}
              className="btn-secondary Flight-font16 border-0 px-4"
            >
              Cancel
            </Button>
          </Stack>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
