import _ from "lodash";

const typeAdult ='ADULT';
const typeChild ='CHILD';
const typeInfant ='INFANT';

class VendorCharges {
  constructor(isDomestic, isOneway, convenienceArr) {
    let flightType = isDomestic ? "Domestic" : "International";
    let journeyType = isOneway ? "Onward" : "Return";

    const selectedObj = _.find(
      convenienceArr,
      (conFeeObj) =>
        flightType === conFeeObj.type && journeyType === conFeeObj.journey
    );

    this.getBookingCharges = selectedObj.bookingCharges;
    this.getResheduleCharges = selectedObj.reschedulingCharges;
    this.getCancellationCharges = selectedObj.cancellationCharges;
  }
}


export class PassengerCounter {
  constructor (passengerObj) {
    let adultCount = 0;
    let childCount = 0;
    let infantCount = 0;
    for (let passengerDetail of passengerObj) {
      adultCount += passengerDetail.type === typeAdult;
      childCount += passengerDetail.type === typeChild;
      infantCount += passengerDetail.type === typeInfant;
    }

    this.passengers =  {adultCount:adultCount, childCount, infantCount}
  }
}

export default VendorCharges;
