import React from "react";
import numberToIndCurr from "../../../../util/priceUtil";
import RadioInput from "../../../elements/input/radioInput";
import string from "../../../../util/UtilManager";
import Image from "../../../elements/input/image";
import { renderImage } from "../../../../util/flightUtil";
const { v4: uuidv4 } = require("uuid");

const FareElement = ({ index, name, fare, selected, onChange }) => {
  let {
    id: fareId,
    fd: {
      ADULT: {
        fC: { TF: totalFare },
        mI,
        rT,
        cc,
      },
    },
    fareIdentifier,
  } = fare;
  return (
    <div className="border rounded py-1 Flight-fares px-md-3 px-2">
      <div className="form-check Flight-fc">
        <RadioInput
          className="form-check-input radio_animated"
          name={name}
          id={"Regular-Fare" + fareId}
          defaultChecked={fareId === selected.id}
          onChange={() => onChange(index, fare)}
        />
        <label
          className="form-check-label Flight-font-semibold Flight-cursor-pointer"
          htmlFor={"Regular-Fare" + fareId}
        >
          {`${string.string.transform.capitalizeEachWord(
            fareIdentifier.replace(/_/g, " ")
          )} ${numberToIndCurr(totalFare)}`}
        </label>
      </div>
      <ul className="Flight-font12 ps-0 mb-0">
              {rT ? (
          <li className={rT === 0 ? "text-danger" : ""}>
            <Image
              src={renderImage(
                rT === 0
                  ? "nonreunfd-icon.svg"
                  : rT === 1 || rT === 2
                  ? "tick-icon.svg"
                  : "nonreunfd-icon.svg"
              )}
              className="me-2"
              alt={rT === 0 ? "nonrefund" : "tick"}
            />
            {rT === 2
              ? "Partial Refundable"
              : rT === 0
              ? "Non-refundable"
              : "Refundable"}
          </li>
        ) : null}

        <li className={mI ? "" : "text-danger"}>
          <Image
            src={renderImage(mI ? "tick-icon.svg" : "nonreunfd-icon.svg")}
            className="me-2"
            alt="tick"
          />
          Free Meal
        </li>
      </ul>
    </div>
  );
};

const FareList = ({ index, fares, selectedFare, onChange }) => {
  const uniqueId = uuidv4();
  return (
    <div className="bookFlightDetail d-block">
      <p className="Flight-font-semibold mb-1 Flight-font14">View Fares</p>
      <div className="Flight-custom-select">
        <div className="d-flex flex-wrap rounded gap-2">
          {fares.map((fare) => (
            <FareElement
              key={fare.id}
              index={index}
              name={uniqueId}
              selected={selectedFare}
              fare={fare}
              onChange={onChange}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FareList;
