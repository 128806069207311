export const GET_AMENDMENT_CHARGES = "/cancellationcharges";
export const GET_BOOKING = "/getbookings";
export const BOOK_FLIGHT = "/bookflight";
export const SUBMIT_CANCELLATION = "/submitcancellation";
export const REQUEST_RESCHEDULE = "/requestreschedule";

export const GET_AIRPORT_DATA = "/getairports";
export const GET_FARE_RULE = "/getfarerules";
export const GET_FARE_N_CLASS = "/getsearchfilters";
export const GET_COUNTRY_CODE = "/getcountrycode";
export const GET_SEARCH_FLIGHT = "/searchflight";

export const GET_CONVIENCE_SETTING = "/getconvenienceconfig";
export const GET_SSR_SETTING = "/getssrconfigsbyiata";
export const GET_RESCHEDULE_FLIGHTS = "/getReschedules";
export const CLOSE_RESCHEDULE_FLIGHTS = "/closeReschedule";