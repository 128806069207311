import ExternalsManager from "../../commons/externals/ExternalsManager";
import {
  CLOSE_RESCHEDULE_FLIGHTS,
  GET_CONVIENCE_SETTING,
  GET_RESCHEDULE_FLIGHTS,
  GET_SSR_SETTING
} from "../../constants/endpoints";

const getConvenienceConfig = async () => {
  let payload = {
    gst:true
  };
  let response = await ExternalsManager.API.post(
    GET_CONVIENCE_SETTING,
    payload
  );
  if (response?.type === "failure") {
    window.alert(
      `Status Code: ${response.status}\nMessage: ${response.message}`
    );
  } else {
    return response?.data;
  }
};

const getSsrByCode = async (onwardIotaCode, returnIotaCode = "") => {
  return ExternalsManager.API.post(GET_SSR_SETTING, {
    code: [onwardIotaCode, returnIotaCode],
  });
};

const getRescheduleFlights = async (page, rescheduleType,search) => {
  return ExternalsManager.API.post(GET_RESCHEDULE_FLIGHTS, {page : page, type:rescheduleType,search:search})
}

const closeRescheduleFlight = async (payload) => {
  return ExternalsManager.API.post(CLOSE_RESCHEDULE_FLIGHTS, payload)
}

export { closeRescheduleFlight, getConvenienceConfig, getRescheduleFlights, getSsrByCode };