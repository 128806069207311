import { INITIAL_PASSENGER_STATE } from "../../constants/index";
let init_passengerState = INITIAL_PASSENGER_STATE;

const PASSENGER_LIMIT = 9;

const passengerReducer = (state = init_passengerState, action) => {
  const { totalPassenger, adult, child, infant } = state;
  const { value, data } = action.payload;
  let updateTotalPassenger = totalPassenger + value;
  switch (action.type) {
    case "UpdatedAdult":
      if (
        (adult === 1 && value < 0) ||
        adult + child + value > PASSENGER_LIMIT
      ) {
        return state;
      }
      return {
        ...state,
        adult: adult + value,
        infant: adult + value < infant ? infant - 1 : infant,
        totalPassenger:
          adult + value < infant
            ? updateTotalPassenger - 1
            : updateTotalPassenger,
      };
    case "UpdatedChild":
      if (
        (child === 0 && value < 0) ||
        adult + child + value > PASSENGER_LIMIT
      ) {
        return state;
      }
      return {
        ...state,
        child: child + value,
        totalPassenger: updateTotalPassenger,
      };
    case "UpdatedInfant":
      if ((infant === 0 && value < 0) || infant + value > adult) {
        return state;
      }
      return {
        ...state,
        infant: infant + value,
        totalPassenger: updateTotalPassenger,
      };
    case "SetState":
      return data;
    default:
      return state;
  }
};

export { passengerReducer, init_passengerState };
