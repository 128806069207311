const BASE_URL =process.env.REACT_APP_BASE_URL;


class callAPI {
  static async unget(url, params, authToken, accountId) {
    void 0 === params && (params = {});

    let apiResponse = {};
    let reqURL = Object.keys(params).reduce(
      (acc, curr) => `${acc}${curr}=${encodeURIComponent(params[curr])}&`,
      BASE_URL + url + "?"
    );

    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          
        },
      };

      apiResponse = await fetch(reqURL, requestOptions);
    } catch (e) {
      console.log("ERROR in fetching request => ", e);
      throw Error;
    }

    return apiResponse.json();
  }

  static async post(url, body, authToken, accountId) {
    let apiResponse = {};

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify(body),
      };
      apiResponse = await fetch(BASE_URL + url, requestOptions);
    } catch (e) {
      throw Error;
    }

    return apiResponse.json();
  }

  static async upload(url, dataObj, authToken, accountId = "") {
    let apiResponse = {};
    let formData = new FormData();

    try {
      for (var key in dataObj) {
        formData.append(key, dataObj[key]);
      }

      const requestOptions = {
        method: "POST",
        headers: {
          authorization: authToken,
          accountId,
        },
        body: formData,
      };

      apiResponse = await fetch(BASE_URL + url, requestOptions);
    } catch (e) {
      console.log("ERROR in fetching request => ", e);
      throw Error;
    }

    return apiResponse.json();
  }

  static async put(url, body, authToken, refreshToken) {
    let apiResponse = {};

    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
         
        },
        body: JSON.stringify(body),
      };

      apiResponse = await fetch(url, requestOptions);
    } catch (e) {
      console.log("ERROR in fetching request => ", e);
      throw Error;
    }

    return apiResponse.json();
  }

  static async delete(url, params, authToken) {
    void 0 === params && (params = {});

    let apiResponse = {};
    let reqURL = Object.keys(params).reduce(
      (acc, curr) => `${acc}${curr}=${encodeURIComponent(params[curr])}&`,
      BASE_URL + url + "?"
    );

    try {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          authorization: authToken,
        },
      };

      apiResponse = await fetch(reqURL, requestOptions);
    } catch (e) {
      console.log("ERROR in fetching request => ", e);
      throw Error;
    }

    return apiResponse.json();
  }

  static async patch(url, body, authToken) {
    let apiResponse = {};

    try {
      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        
        },
        body: JSON.stringify(body),
      };

      apiResponse = await fetch(BASE_URL + url, requestOptions);
    } catch (e) {
      console.log("ERROR in fetching request => ", e);
      throw Error;
    }

    return apiResponse.json();
  }

  
 
}

export default callAPI;
