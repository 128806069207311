import React, { useEffect, useState } from "react";
import "./App.css";
import DataContextProvider from "./commons/context/dataContext";
import RootSwitch from "./routes/RootSwitch";
import { getConvenienceConfig } from "./web/services/adminService";
import Header from "./web/pages/Header/Header";
import Footer from "./web/pages/footer/Footer";
import {
  getAirportData,
  getFareTypeData,
  getFlightClassData,
} from "./web/services/airlineService";
import { useHistory } from "react-router-dom";
import Landing from "./commons/landing/landing";

export default function App() {
  const history = useHistory();
  const [airportData, setAirportData] = useState([]);
  const [cabinData, setCabinData] = useState([]);
  const [fareData, setFareData] = useState([]);
  const [convenienceFee, setConvenienceFee] = useState([]);
  const [platform, setPlatform] = useState("");

  useEffect(() => {
    Promise.all([
      getAirportData(),
      getFlightClassData(),
      getFareTypeData(),
      getConvenienceConfig(),
    ]).then((values) => {
      setAirportData(values[0]);
      setCabinData(values[1]);
      setFareData(values[2]);
      setConvenienceFee(values[3]);
    });
  }, []);

  useEffect(() => {
    const currentPlatform = getPlatform();
    setPlatform(currentPlatform);
  }, []);

  const getPlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/manageheaderfooterwebview/.test(userAgent) && !window.MSStream) {
      return "mobileinapp";
    }
    return "web";
  };

  return (
    <React.Fragment>
      
        <DataContextProvider
          airportData={airportData}
          cabinData={cabinData}
          fareData={fareData}
          convenienceFee={convenienceFee}
        >
          {/* <div className="Flight-wrapper">
          
            <Header id="divToHide" history={history} />
          
          <RootSwitch />
          <div className="push"></div>
          </div>
         
            <Footer id="divToHide" history={history} />
        */}

        <Landing/>
        </DataContextProvider>
        
    
    </React.Fragment>
  );
}
