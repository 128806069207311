export const INITIAL_DEPART_PLACE = {
  city: "Bengaluru",
  code: "BLR",
  country: "India",
};

export const INITIAL_ARRIVAL_PLACE = {
  city: "Delhi",
  code: "DEL",
  country: "India",
};

export const INITIAL_PASSENGER_STATE = {
  totalPassenger: 1,
  adult: 1,
  child: 0,
  infant: 0,
};

export const INITIAL_SEARCH_DATA = {
  onwardFlights: [],
  returnFlights: [],
};

export const INITIAL_RESCHEDULE_DATA = {
  userId: "",
  orderId: "",
  request: [
    {
      pnrNo: "",
      date: ""
    },
    {
      pnrNo: "",
      date: ""
    }
  ],
  sameFlight: true,
  note: ""
};

export const INITIAL_RESCHEDULE_FORM_DATA = { 
  rescheduleId: "", 
  isRescheduled:false, 
  remark: "" 
};

export const INITIAL_FARE_TYPE = "REGULAR";
export const INITIAL_FLIGHT_CLASS = "ECONOMY";
