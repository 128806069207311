import _, { capitalize } from "lodash";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col, Container, Modal, Row, Stack, Tab, Tabs } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";
import { useHistory, useLocation } from "react-router-dom";
import ResultCard from "../../../commons/artefacts/components/FlightResultList/ResultCard";
import { ResultCardInt } from "../../../commons/artefacts/components/FlightResultList/ResultListInt";
import Button from "../../../commons/artefacts/elements/button/primary";
import CheckboxInput from "../../../commons/artefacts/elements/input/checkboxInput";
import EmailInput from "../../../commons/artefacts/elements/input/emailInput";
import Image from "../../../commons/artefacts/elements/input/image";
import TextInput from "../../../commons/artefacts/elements/input/textInput";
import { DataContext } from "../../../commons/context/dataContext";
import { ReviewContext } from "../../../commons/context/reviewContext";
import ExternalsManager from "../../../commons/externals/ExternalsManager";
import string from "../../../commons/util/UtilManager";
import {
  getDateByFormat,
  getDateFromString,
} from "../../../commons/util/dateTimeUtil";
import { renderImage } from "../../../commons/util/flightUtil";
import VendorCharges from "../../../commons/util/helper";
import numberToIndCurr, {
  calculateFare,
} from "../../../commons/util/priceUtil";
import { getSsrByCode } from "../../services/adminService";
import FareSummary from "./FareSummary";
import MealMenu from "./MealMenu";
import PassengerDetailForm from "./PassengerDetailForm";
import SeatLayout from "./SeatLayout";
import ViewBaggage from "./ViewBaggage";
import LoginModal from "../Auth/LoginModal";
import firebase from "../../../commons/util/firebase/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";
let updatedStateData = null;
const grpByAndTransform = (data, get) => {
  return _(data)
    .groupBy("ride")
    .mapValues((rideData) =>
      _(rideData)
        .groupBy("deparrCode")
        .mapValues((deparrData) => _.map(deparrData, get))
        .value()
    )
    .value();
};
const auth = getAuth(firebase);

const FlightDetail = () => {
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  const { pathname, state } = useLocation();
  const [loginModal, setLoginModal] = useState(false);
  const {
    email,
    setEmail,
    phoneNum,
    setPhoneNum,
    addOnModalContent,
    setAddonsModalContent,
    travellerInfo,
    setTravellerInfo,
    paxValidator,
    setPaxValidator,
    addOnSwitch,
    setAddOnSwitch,
    paxDeatsComplete,
    setPaxDeatsComplete,
    contactDeatsComplete,
    setContactDeatsComplete,
    reviewData,
    setReviewData,
    seatMapData,
    setSeatMapData,
    fareSummary,
    setFareSummary,
    finalGrandTotal,
    setFinalGrandTotal,
    conditions,
    setConditions,
    showGst,
    setShowGst,
    gstInfo,
    setGstInfo,
  } = useContext(ReviewContext);

  const { convenienceFee, showFareDetail, setShowFareDetail, journeyType } =
    useContext(DataContext);
  useEffect(() => {
    if (loading) setLoader(true);
    else if (!user || error || !state?.hasOwnProperty("id")) {
      //  history.push("/");
      setLoginModal(true);
    } else {
      setLoader(false);
      setLoginModal(false);
    }
    //setUser(auth.currentUser)

    return null;
  }, [user, loading, error]);

  const [ssrBtnObj, setSsrBtnObj] = useState({
    onward: {
      seat: true,
      meal: true,
      baggage: true,
      code: state?.data[0].flightResult.sI[0].da.code,
    },
    return: {
      seat: false,
      meal: false,
      baggage: false,
      code: state?.data[1]?.flightResult?.sI[0]?.da?.code || "",
    },
  });
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [seatMapLoader, setSeatMapLoader] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [gstEmailError, setGstEmailError] = useState("");
  const [gstPNError, setGstPNError] = useState("");
  const [phoneNumError, setPhoneNumError] = useState("");
  const [gstError, setGstError] = useState("");
  const [taFlag, setTAFlag] = useState(false);
  const [duplicateName, setDuplicateName] = useState(false);
  const [showSeatDetail, setShowSeatDetail] = useState({});
  const [showMealDetail, setShowMealDetail] = useState({});
  const [showBaggageDetail, setShowBaggageDetail] = useState({});
  const [minExpDate, setMinExpDate] = useState(new Date());
  const [vendorCharges, setVendorCharges] = useState(0);
  let [userId, setUserId] = useState("");
  const [mftTotal, setMFTTotal] = useState(0);
  const [mfTotal, setMFTotal] = useState(0);
  let mgtFee = 0;
  useEffect(() => {
    if (loading || error) {
    } else {
      let encodedUserId = btoa(user?.uid); //only for bial.genietalk.ai purpose
      setUserId(encodedUserId);
    }
  }, []);

  const handlePhoneNumChange = (value, country) => {
    // Remove all non-digit characters from the phone number
    const phoneNumber = value.replace(/\D/g, "");
    setPhoneNum(phoneNumber);

    // Validate the phone number
    const isValid = validatePhoneNumber(phoneNumber, country);
    if (isValid) {
      setPhoneNumError("");
    } else {
      setPhoneNumError("Invalid phone number");
    }
  };

  const validatePhoneNumber = (phoneNumber, country) => {
    // Check if the phone number is valid for the selected country
    const isNumberValid = new RegExp(country.validationRegex).test(phoneNumber);

    // You can add additional custom validation rules here
    const isCustomRuleValid = phoneNumber.length >= 12;
    return isNumberValid && isCustomRuleValid;
  };
  function validateEmail(email) {
    const re =
      /^[a-zA-Z0-9]+([._%+-]*[a-zA-Z0-9])*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/;
    return re.test(String(email).toLowerCase());
  }
  function handleEmailChange(e) {
    const emailValue = e.target.value;
    setEmail(emailValue);

    if (validateEmail(emailValue)) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email address.");
    }
  }

  useEffect(() => {
    if (travellerInfo[0]?.ssrSeatInfos) {
      let data = grpByAndTransform(travellerInfo[0]?.ssrSeatInfos, "code");
      setShowSeatDetail(data);
    }
  }, [travellerInfo[0]?.ssrSeatInfos]);

  useEffect(() => {
    if (travellerInfo[0]?.ssrMealInfos) {
      let data = grpByAndTransform(travellerInfo[0]?.ssrMealInfos, "code");
      setShowMealDetail(data);
    }
  }, [travellerInfo[0]?.ssrMealInfos]);

  useEffect(() => {
    if (travellerInfo[0]?.ssrBaggageInfos) {
      let data = grpByAndTransform(travellerInfo[0]?.ssrBaggageInfos, "code");
      setShowBaggageDetail(data);
    }
  }, [travellerInfo[0]?.ssrBaggageInfos]);

  useEffect(() => {
    let depCode = ssrBtnObj.onward.code;
    let reCode = ssrBtnObj.return.code;
    window.scrollTo(0, 0);

    getSsrByCode(depCode, reCode).then(({ data }) => {
      let reSeat = false;
      let reMeal = false;
      let reBaggage = false;

      const {
        seat: onSeat = true,
        meal: onMeal = true,
        baggage: onBaggage = true,
      } = _.find(data, (obj) => obj.code === depCode)?.ssrInfo[0] || {};

      if (reCode) {
        const {
          seat = true,
          meal = true,
          baggage = true,
        } = _.find(data, (obj) => obj.code === reCode)?.ssrInfo[0] || {};

        reSeat = seat;
        reMeal = meal;
        reBaggage = baggage;
      }

      setSsrBtnObj({
        onward: {
          seat: onSeat,
          meal: onMeal,
          baggage: onBaggage,
        },
        return: {
          seat: reSeat,
          meal: reMeal,
          baggage: reBaggage,
        },
      });
    });

    getReviewData();
  }, []);

  useEffect(() => {
    //check if all the passenger deats are filled
    let totalPaxCount =
      reviewData?.searchQuery?.paxInfo?.ADULT +
      reviewData?.searchQuery?.paxInfo?.CHILD +
      reviewData?.searchQuery?.paxInfo?.INFANT;

    let incompleteFlag = false;

    let compulsionFlag = [];

    travellerInfo.forEach((x) => {
      compulsionFlag.push({
        pIndex: x.index,
        pKey: "fN",
        result: x.fN ? true : false,
      });
      compulsionFlag.push({
        pIndex: x.index,
        pKey: "lN",
        result: x.lN ? true : false,
      });

      if (conditions?.pcs?.pm) {
        // check for passport validations
        compulsionFlag.push({
          pIndex: x.index,
          pKey: "eD",
          result: x.eD ? true : !conditions.pcs.pped,
        });
        compulsionFlag.push({
          pIndex: x.index,
          pKey: "pid",
          result: x.pid ? true : !conditions.pcs.pid,
        });
        compulsionFlag.push({
          pIndex: x.index,
          pKey: "pNat",
          result: x.pNat ? true : false,
        });
        compulsionFlag.push({
          pIndex: x.index,
          pKey: "pNum",
          result: x.pNum ? true : false,
        });
        compulsionFlag.push({
          pIndex: x.index,
          pKey: "dob",
          result: x.dob ? true : !conditions.pcs.dobe,
        });
      } else {
        if (x.type === "ADULT") {
          compulsionFlag.push({
            pIndex: x.index,
            pKey: "dob",
            result: x.dob ? true : !conditions?.dob?.adobr,
          });
        } else if (x.type === "INFANT") {
          compulsionFlag.push({
            pIndex: x.index,
            pKey: "dob",
            result: x.dob ? true : !conditions?.dob?.idobr,
          });
        } else if (x.type === "CHILD") {
          compulsionFlag.push({
            pIndex: x.index,
            pKey: "dob",
            result: x.dob ? true : !conditions?.dob?.cdobr,
          });
        }
      }
    });

    setPaxValidator(compulsionFlag);

    incompleteFlag =
      compulsionFlag.filter((x) => x.result === false).length > 0
        ? true
        : false;

    if (totalPaxCount !== travellerInfo.length) {
      incompleteFlag = true;
    }

    if (showGst === true) {
      Object.keys(gstInfo).forEach((key) => {
        if (!gstInfo[key]) {
          incompleteFlag = true;
        }
      });

      if (gstError || gstEmailError || gstPNError) {
        incompleteFlag = true;
      }
    }

    if (
      reviewData?.searchQuery?.searchModifiers?.pft === "STUDENT" ||
      reviewData?.searchQuery?.searchModifiers?.pft === "ARMED_FORCES"
    ) {
      travellerInfo.forEach((x) => {
        if (x.di === "") {
          incompleteFlag = true;
        }
      });
    } else if (
      reviewData?.searchQuery?.searchModifiers?.pft === "SENIOR_CITIZEN"
    ) {
      travellerInfo.forEach((x) => {
        if (x.dob === "") {
          incompleteFlag = true;
        }
      });
    }

    setPaxDeatsComplete(!incompleteFlag);

    // check if any flight has ssr options :

    let ssrMeal = false;
    let ssrBag = false;
    reviewData?.tripInfos?.forEach((x) => {
      x.sI.forEach((y) => {
        if (y?.ssrInfo?.hasOwnProperty("MEAL") && y.ssrInfo.MEAL.length > 0) {
          ssrMeal = true;
        }
        if (
          y.ssrInfo?.hasOwnProperty("BAGGAGE") &&
          y.ssrInfo.BAGGAGE.length > 0
        ) {
          ssrBag = true;
        }
      });
    });

    setAddOnSwitch({
      seat: reviewData?.conditions?.isa ? true : false,
      meal: ssrMeal,
      bag: ssrBag,
    });

    //Calculate Total of SSR OR ADDons :

    let addOn = { price: 0, detail: "" };
    let seatAddOnFlag = false;
    travellerInfo
      .filter((x) => x !== "INFANT")[0]
      ?.ssrSeatInfos?.map((y) => {
        addOn.price = addOn.price + y.amount;
        seatAddOnFlag = true;
        return null;
      });
    let mealAddOnFlag = false;
    travellerInfo
      .filter((x) => x !== "INFANT")[0]
      ?.ssrMealInfos?.map((y) => {
        addOn.price = addOn.price + y.amount;
        mealAddOnFlag = true;
        return null;
      });
    let bagAddOnFlag = false;
    travellerInfo
      .filter((x) => x !== "INFANT")[0]
      ?.ssrBaggageInfos?.map((y) => {
        addOn.price = addOn.price + y.amount;
        bagAddOnFlag = true;
        return null;
      });

    if (seatAddOnFlag) {
      addOn.detail = addOn.detail ? addOn.detail + ", Seats" : "Seats";
    }
    if (mealAddOnFlag) {
      addOn.detail = addOn.detail ? addOn.detail + ", Meals" : "Meals";
    }
    if (bagAddOnFlag) {
      addOn.detail = addOn.detail ? addOn.detail + ", Baggages" : "Baggages";
    }

    //updating fareSummary with Passengers and their fares
    let passenger = [
      {
        ADULT: reviewData?.searchQuery?.paxInfo.ADULT,
        price: 0,
      },
      {
        CHILD: reviewData?.searchQuery?.paxInfo.CHILD,
        price: 0,
      },
      {
        INFANT: reviewData?.searchQuery?.paxInfo.INFANT,
        price: 0,
      },
    ];

   
reviewData?.tripInfos?.forEach((x) => {
  x.totalPriceList.forEach((y) => {
    let adultCount = passenger[0].ADULT;
    let adultPrice = y?.fd?.ADULT?.fC?.BF || 0;
  
    let adultTP = eval(adultCount * adultPrice);
    passenger[0].price = eval(passenger[0].price + adultTP);
    let childCount = passenger[1].CHILD || 0;
    let childPrice = y?.fd?.CHILD?.fC?.BF || 0;

    let childTP = childCount * childPrice;
    passenger[1].price = passenger[1].price + childTP;

    let infantCount = passenger[2].INFANT || 0;
    let infantPrice = y?.fd?.INFANT?.fC?.BF || 0;
    
    let infantTP = infantCount * infantPrice;
    passenger[2].price = passenger[2].price + infantTP;
  });
});

    let baseFare = reviewData?.totalPriceInfo?.totalFareDetail?.fC?.BF
      ? reviewData?.totalPriceInfo?.totalFareDetail?.fC?.BF
      : 0;

    let feeCharges = 0;
    let feeBreakup =
      reviewData?.totalPriceInfo?.totalFareDetail?.afC?.hasOwnProperty("TAF")
        ? reviewData?.totalPriceInfo?.totalFareDetail?.afC?.TAF
        : {};
    // Backup "MF" and "MFT" keys from feeBreakup
    if (feeBreakup.hasOwnProperty("MF")) {
      setMFTotal(feeBreakup?.MF);
    }
    if (feeBreakup.hasOwnProperty("MFT")) {
      setMFTTotal(feeBreakup?.MFT);
    }

    for (const key in feeBreakup) {
      if (feeBreakup.hasOwnProperty(key)) {
        feeCharges = feeCharges + feeBreakup[key];
      }
    }
    setFareSummary({
      ...fareSummary,
      passengers: passenger,
      addons: addOn,
      baseFare: baseFare,
      feeCharges: feeCharges,
      vendorCharges: vendorCharges,
      feeBreakup: feeBreakup,
    });

    if (travellerInfo.length > 1) {
      checkDuplicateNames();
    }
    mgtFee = mfTotal + mftTotal;
  }, [reviewData, seatMapData, travellerInfo, gstInfo, showGst]);

  useEffect(() => {
    if (email && phoneNum && !phoneNumError && !emailError)
      setContactDeatsComplete(true);
    else setContactDeatsComplete(false);
  }, [email, phoneNum]);

  const getReviewData = async () => {
    setLoader(true);
    let data = {
      priceIds: state?.id,
    };

    let response = await ExternalsManager?.API.post(`/reviewflight`, data);
    if (response?.type === "success") {
      setLoader(false);
      setReviewData(response?.data);
      setConditions(response?.data?.conditions);
      setMinExpDate(
        getDateFromString(response.data.tripInfos.at(-1).sI.at(-1).at)
      );
    } else {
      setAddonsModalContent(null);
      if (response?.status === 404) {
        window.alert(
          `Status Code: ${response?.status}\nMessage: ${response?.message?.errorMsg}`
        );
        history.push("/flights");
      } else {
        window.alert(
          `Status Code: ${
            response?.status
          }\nMessage: ${"Something went wrong. Please contact support for assistance."}`
        );
        history.push("/flights");
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    if (convenienceFee?.length) {
      let vendorObj = new VendorCharges(
        reviewData?.searchQuery?.isDomestic,
        reviewData?.searchQuery?.searchType !== "RETURN",
        convenienceFee
      );
      setVendorCharges(vendorObj.getBookingCharges);
    }
  }, [convenienceFee, reviewData]);

  const getSeatMapData = async (target) => {
    setSeatMapLoader(true);
    setAddonsModalContent(target);
    let data = {
      bookingId: reviewData?.bookingId,
    };
    let response = await ExternalsManager?.API.post(`/getseatsinfo`, data);
    if (response?.type === "success") {
      setSeatMapData(response?.data);
      setSeatMapLoader(() => false);
    } else {
      setAddonsModalContent(null);
      if (response?.status === 404) {
        window.alert(
          `Status Code: ${response?.status}\nMessage: ${response?.message?.errorMsg}`
        );
        setLoader(false);
      } else {
        window.alert(
          `Status Code: ${
            response?.status
          }\nMessage: ${"Something went wrong. Please contact support for assistance."}`
        );
      }
      setAddonsModalContent(null);
    }
  };

  const handleAddonModals = (e) => {
    let targetName = e?.target?.name;
    targetName === "Select Seats"
      ? getSeatMapData(targetName)
      : setAddonsModalContent(targetName);
  };

  const handlePassengerDetail = (
    index,
    key,
    value,
    state,
    handleState,
    type
  ) => {
    const passengers = [...state];
    let editedFlag = false;
    passengers.map((i) => {
      if (i.index === index) {
        editedFlag = true;
        if (key === "dob" && i.pid) {
          i.pid = i.pid >= value ? i.pid : null;
        }
        i[key] = value;
      }
      return i;
    });
    if (editedFlag === false) {
      let obj = {
        index: index,
        ti: "Mr.",
        fN: "",
        lN: "",
        dob: "",
        type: type,
        di: "",
        diType: "",
      };
      obj[key] = value;
      passengers.push(obj);
    }
    passengers.sort((a, b) => a.index - b.index);
    handleState([...passengers]);
  };

  const bookingHadler = async (isDirect = false) => {
    const { tripInfos: trpInfos } = reviewData;
    setLoader(true);
    travellerInfo.forEach((traveller) => {
      traveller.diType =
        reviewData?.searchQuery?.searchModifiers?.pft === "STUDENT"
          ? "studentId"
          : reviewData?.searchQuery?.searchModifiers?.pft === "ARMED_FORCES"
          ? "defenceId"
          : "";
      traveller.ti = traveller.ti.replace(/\./g, ""); //modify the "ti" key

      if (traveller.eD) {
        const edDate = new Date(traveller?.eD);
        traveller.eD = edDate?.toISOString()?.split("T")[0];
      }

      // Convert the date format of "pid" key to "YYYY-MM-DD"

      if (traveller.pid) {
        const pidDate = new Date(traveller?.pid);
        traveller.pid = pidDate?.toISOString()?.split("T")[0];
      }

      if (traveller.dob === "") {
        delete traveller.dob;
      } else {
        const dateB = new Date(traveller?.dob);
        traveller.dob = dateB?.toISOString()?.split("T")[0];
      }

      // update the ti key for infant and child travellers
      if (traveller.type === "INFANT" || traveller.type === "CHILD") {
        if (traveller.ti !== "Ms." && traveller.ti !== "Master") {
          traveller.ti = "Master";
        }
      }
    });
    //-------------------------   wrote a logic to rearrage SSR info for the multiple pax booking failure issue----------------

    const ssrCollection = {};

    if (travellerInfo[0].hasOwnProperty("ssrSeatInfos"))
      ssrCollection.ssrSeatInfos = travellerInfo[0].ssrSeatInfos;
    if (travellerInfo[0].hasOwnProperty("ssrMealInfos"))
      ssrCollection.ssrMealInfos = travellerInfo[0].ssrMealInfos;
    if (travellerInfo[0].hasOwnProperty("ssrBaggageInfos"))
      ssrCollection.ssrBaggageInfos = travellerInfo[0].ssrBaggageInfos;

    travellerInfo.map((pax) => {
      pax.ssrSeatInfos = [];
      pax.ssrMealInfos = [];
      pax.ssrBaggageInfos = [];
      return pax;
    });

    if (ssrCollection.hasOwnProperty("ssrSeatInfos")) {
      let groupedArr = [];
      let groupedObj = {};

      ssrCollection.ssrSeatInfos.forEach((obj) => {
        let key = obj.key;

        if (!groupedObj[key]) {
          groupedObj[key] = [];
        }

        groupedObj[key].push(obj);
      });

      for (let key in groupedObj) {
        groupedArr.push(groupedObj[key]);
      }
      ssrCollection.ssi = groupedArr;

      ssrCollection.ssi.forEach((segment) => {
        segment.forEach((seat, index) => {
          travellerInfo[index].ssrSeatInfos.push(seat);
        });
      });
    }

    if (ssrCollection.hasOwnProperty("ssrMealInfos")) {
      let groupedArr = [];
      let groupedObj = {};

      ssrCollection.ssrMealInfos.forEach((obj) => {
        let key = obj.key;

        if (!groupedObj[key]) {
          groupedObj[key] = [];
        }

        groupedObj[key].push(obj);
      });

      for (let key in groupedObj) {
        groupedArr.push(groupedObj[key]);
      }
      ssrCollection.smi = groupedArr;

      ssrCollection.smi.forEach((segment) => {
        segment.forEach((meal, index) => {
          travellerInfo[index].ssrMealInfos.push(meal);
        });
      });
    }

    if (ssrCollection.hasOwnProperty("ssrBaggageInfos")) {
      let groupedArr = [];
      let groupedObj = {};

      ssrCollection.ssrBaggageInfos.forEach((obj) => {
        let key = obj.key;

        if (!groupedObj[key]) {
          groupedObj[key] = [];
        }

        groupedObj[key].push(obj);
      });

      for (let key in groupedObj) {
        groupedArr.push(groupedObj[key]);
      }
      ssrCollection.sbi = groupedArr;

      ssrCollection.sbi.forEach((segment) => {
        segment.forEach((bag, index) => {
          travellerInfo[index].ssrBaggageInfos.push(bag);
        });
      });
    }
    //--------------------------------------Logice ends-------------------------------------------------------

    let finalPayload = {};
    finalPayload.userId = userId;
    finalPayload.sourceCode =
      reviewData?.searchQuery?.routeInfos[0]?.fromCityOrAirport?.code;
    finalPayload.destinationCode =
      reviewData?.searchQuery?.routeInfos[0]?.toCityOrAirport?.code;
    finalPayload.cabinClass = reviewData?.searchQuery?.cabinClass;
    finalPayload.searchType = reviewData?.searchQuery?.searchType;
    finalPayload.isDomestic = reviewData?.searchQuery?.isDomestic;
    finalPayload.isCustomCombination = reviewData?.searchQuery
      ?.isCustomCombination
      ? reviewData?.searchQuery?.isCustomCombination
      : false;
    finalPayload.isOneWay =
      reviewData?.searchQuery?.searchType === "RETURN" ? false : true;
    finalPayload.isDomesticReturn =
      reviewData?.searchQuery?.isDomestic &&
      reviewData?.searchQuery?.searchType === "RETURN"
        ? true
        : false;
    finalPayload.bookingId = reviewData?.bookingId;
    finalPayload.paxInfo = reviewData?.searchQuery?.paxInfo;
    finalPayload.tripInfos = reviewData?.tripInfos;
    finalPayload.totalFareDetail = reviewData?.totalPriceInfo?.totalFareDetail;
    finalPayload.tripInfos[0].fare = _.get(
      state,
      "data[0].flightResult.selectedFare.fareIdentifier"
    );
    if (finalPayload.tripInfos.length > 1) {
      finalPayload.tripInfos[1].fare = _.get(
        state,
        "data[1].flightResult.selectedFare.fareIdentifier"
      );
    }
    finalPayload.travellerInfo = travellerInfo;
    finalPayload.contactInfo = { email: email, contact: phoneNum };
    finalPayload.totalAmount = finalGrandTotal;
    finalPayload.displayAmount =
      finalGrandTotal -
      (mfTotal + mftTotal) +
      vendorCharges *
        (reviewData?.searchQuery?.paxInfo.ADULT +
          reviewData?.searchQuery?.paxInfo.CHILD);
    finalPayload.convFee =
      vendorCharges *
      (reviewData?.searchQuery?.paxInfo.ADULT +
        reviewData?.searchQuery?.paxInfo.CHILD);
    finalPayload.gstInfo = gstInfo;

    finalPayload.orderExpiry =
      trpInfos[trpInfos.length - 1].sI[
        trpInfos[trpInfos.length - 1].sI.length - 1
      ].at;

    let response = await ExternalsManager?.API.post(
      "/makepayment",
      finalPayload
    );
    if (response?.type === "success") {
      if (response?.data?.data?.responseCode === "0000") {
        let redirectionURL = response?.data?.data?.redirectionURL;
        window.location.replace(redirectionURL);
      } else {
        if (response?.status === 404) {
          window.alert(
            `Status Code: ${response?.status}\nMessage: ${response?.message?.errorMsg}`
          );
          setLoader(false);
        } else {
          setLoader(false);
          window.alert(
            `Status Code: ${
              response?.status
            }\nMessage: ${"Something went wrong. Please contact support for assistance."}`
          );
        }
        setLoader(false);
        setShowModal(true);
      }
    } else if (response?.type === "failure") {
      setLoader(false);
      window.alert(
        `Status Code: ${
          response?.status
        }\nMessage: ${"Something went wrong. Please contact support for assistance."}`
      );
    }
  };

  const checkDuplicateNames = () => {
    const duplicateNames = travellerInfo.reduce((acc, obj) => {
      const name = obj.fN + " " + obj.lN;
      if (acc[name]) {
        acc[name] += 1;
      } else {
        acc[name] = 1;
      }
      return acc;
    }, {});

    const duplicateNameList = Object.keys(duplicateNames).filter(
      (name) => duplicateNames[name] > 1
    );

    if (duplicateNameList.length > 0) {
      setDuplicateName(true);
    } else {
      setDuplicateName(false);
    }
  };

  const validateGSTPhoneNumber = (number) => {
    const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    return regex.test(number);
  };

  let resultCardData = [];
  if (reviewData) {
    state?.data?.forEach((item1) => {
      reviewData?.tripInfos &&
        reviewData?.tripInfos.forEach((item2) => {
          let obj = {
            flightResult: {
              sI: item2?.sI,
              totalPriceList: item2?.totalPriceList,
              selectedFare: item1?.flightResult?.selectedFare,
              uniqId: item1?.flightResult?.uniqId,
              minFare: item1?.flightResult?.minFare
                ? item1?.flightResult?.minFare
                : null,
              index: item1?.flightResult?.index,
            },
          };
          resultCardData.push(obj);
        });
    });
  }

  return loader ? (
    <Container fluid="xxl" className="Flight-container-xxl">
      <div className="loader-wrapper img-gif mt-0 pt-0">
        <Image src={renderImage("loader.gif")} className="img-fluid" />
      </div>
    </Container>
  ) : (
    <Fragment>
      <Container fluid="xxl" className="px-lg-3 pt-4 px-0 Flight-container-xxl">
        <Row className="Flight-row g-lg-3 g-0">
          <Col lg="9">
            <div className="Flight-detail-flight">
              {reviewData?.alerts ? (
                <div className="px-lg-0 px-3">
                  <div className="partial-note Flight-font12 Flight-font-semibold p-2 mb-lg-2 mb-0 rounded">
                    {/* <Image
                    alt="airlinelogo"
                    className="me-1"
                    src={renderImage("ExclamationMark.svg")}
                    width={20}
                    height={20}
                  /> */}
                    The price has been updated for this route by the airline.
                  </div>
                </div>
              ) : null}

              {reviewData &&
                state?.data?.map(({ flightResult }, cardIndex) => {
                  const { selectedFare } = flightResult;
                  let mgtFee;
                  if (
                    selectedFare?.fd?.ADULT?.afC?.TAF?.MF &&
                    selectedFare?.fd?.ADULT?.afC?.TAF?.MFT
                  ) {
                    const MF = selectedFare.fd.ADULT.afC.TAF.MF;
                    const MFT = selectedFare.fd.ADULT.afC.TAF.MFT;
                    mgtFee = Math.round(MF + MFT);
                  }
                  updatedStateData = state?.data?.map(
                    ({ flightResult }, cardIndex) => {
                      const reviewDataFd =
                        reviewData?.tripInfos?.[cardIndex]?.totalPriceList?.[0]
                          ?.fd;

                      if (reviewDataFd) {
                        const updatedTotalPriceList =
                          flightResult.totalPriceList.map((item) => ({
                            ...item,
                            fd: reviewDataFd,
                          }));

                        return {
                          ...flightResult,
                          totalPriceList: updatedTotalPriceList,
                        };
                      } else {
                        return flightResult;
                      }
                    }
                  );

                  return (
                    <Fragment>
                      <div className="px-lg-0 px-3 Flight-shadow-box pt-lg-0 pt-3 mb-lg-3 mb-0">
                        {state.isIntRd ? (
                          <ResultCardInt
                            mgtFee={mgtFee}
                            key={"result" + cardIndex}
                            flightResult={flightResult}
                            passengers={state?.passengers}
                            path={pathname}
                            showFareDetail={showFareDetail}
                            onChangeShowFareDetail={(id) => {
                              setShowFareDetail(
                                showFareDetail !== id ? id : ""
                              );
                            }}
                          />
                        ) : (
                          <ResultCard
                            mgtFee={mgtFee}
                            key={"result" + cardIndex}
                            flightResult={updatedStateData[cardIndex]}
                            passengers={state?.passengers}
                            path={pathname}
                            showFareDetail={showFareDetail}
                            onChangeShowFareDetail={(id) => {
                              setShowFareDetail(
                                showFareDetail !== id ? id : ""
                              );
                            }}
                          />
                        )}
                      </div>
                    </Fragment>
                  );
                })}
              {journeyType?.type === "Domestic" ||
              reviewData?.searchQuery?.isDomestic ? (
                ""
              ) : (
                <div className="Flight-shadow-box ps-note">
                  <div className="partial-note Flight-font12 Flight-font-semibold p-2 mb-lg-3 mb-0">
                    <Image
                      alt="airlinelogo"
                      className="me-1"
                      src={renderImage("watch-icon.svg")}
                    />
                    The timings mentioned above is in Indian Standard Time (IST)
                  </div>
                </div>
              )}
            </div>
            <div className="Flight-shadow-box bg-white pt-lg-0 pt-2 mb-lg-3">
              <div className="d-flex justify-content-between px-3 py-2 align-items-center">
                <div className="Flight-font16 Flight-font-semibold">
                  Traveller Details{" "}
                  {duplicateName ? (
                    <small className="text-danger">
                      Two or more passengers should not have the same name.
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="Flight-faretype">
                <span className="Flight-font12 px-3 mt-lg-2 d-block">
                  <b>Note:</b> Please make sure you enter the Name as per your
                  govt. photo ID.
                </span>

                {_.range(0, state?.passengers?.adult).map((_elem, i) => (
                  <React.Fragment key={"adult" + i}>
                    <div className="Flight-font14 Flight-font-semibold px-3 mt-1 mb-2">
                      Adult Details
                    </div>

                    <PassengerDetailForm
                      detail={
                        travellerInfo.filter((j) => j.index === i + 100)
                          .length > 0
                          ? travellerInfo.filter((j) => j.index === i + 100)[0]
                          : {
                              ti: "",
                              fN: "",
                              lN: "",
                              dob: "",
                              di: "",
                              diType: "",
                            }
                      }
                      index={i + 100}
                      state={travellerInfo}
                      setState={setTravellerInfo}
                      onChange={handlePassengerDetail}
                      type="ADULT"
                      paxValidator={paxValidator}
                      passport={
                        conditions.hasOwnProperty("pcs") ? conditions.pcs : {}
                      }
                      reviewData={reviewData}
                      minExpDate={minExpDate}
                    />
                  </React.Fragment>
                ))}

                {_.range(0, state?.passengers?.child).map((_elem, i) => (
                  <React.Fragment key={"child" + i}>
                    <div className="Flight-font14 px-3 Flight-font-semibold my-2">
                      Child Details
                    </div>
                    <PassengerDetailForm
                      detail={
                        travellerInfo.filter((j) => j.index === i + 200)
                          .length > 0
                          ? travellerInfo.filter((j) => j.index === i + 200)[0]
                          : { ti: "", fN: "", lN: "", dob: "" }
                      }
                      index={i + 200}
                      state={travellerInfo}
                      setState={setTravellerInfo}
                      onChange={handlePassengerDetail}
                      type="CHILD"
                      paxValidator={paxValidator}
                      passport={
                        conditions.hasOwnProperty("pcs") ? conditions.pcs : {}
                      }
                      minExpDate={minExpDate}
                    />
                  </React.Fragment>
                ))}

                {_.range(0, state?.passengers.infant).map((_elem, i) => (
                  <React.Fragment key={"infant" + i}>
                    <div
                      className="Flight-font14 px-3 Flight-font-semibold my-2"
                      key="infant"
                    >
                      Infant Details
                    </div>

                    <PassengerDetailForm
                      detail={
                        travellerInfo.filter((j) => j.index === i + 300)
                          .length > 0
                          ? travellerInfo.filter((j) => j.index === i + 300)[0]
                          : { ti: "", fN: "", lN: "", dob: "" }
                      }
                      index={i + 300}
                      state={travellerInfo}
                      setState={setTravellerInfo}
                      onChange={handlePassengerDetail}
                      type="INFANT"
                      paxValidator={paxValidator}
                      passport={
                        conditions.hasOwnProperty("pcs") ? conditions.pcs : {}
                      }
                      minExpDate={minExpDate}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
            {[
              ssrBtnObj.onward.seat,
              ssrBtnObj.onward.meal,
              ssrBtnObj.onward.baggage,
              ssrBtnObj.return.seat,
              ssrBtnObj.return.meal,
              ssrBtnObj.return.baggage,
            ].some(Boolean) && (
              <fieldset disabled={!paxDeatsComplete}>
                {" "}
                {(addOnSwitch.bag || addOnSwitch.meal || addOnSwitch.seat) && (
                  <div className="Flight-shadow-box bg-white mb-lg-3 pb-3 pt-2 py-lg-0">
                    <div className="px-3 py-2">
                      <div
                        className={`Flight-font16 Flight-font-bold ${
                          !paxDeatsComplete && "textdanger"
                        }`}
                      >{`Addons ${
                        !paxDeatsComplete
                          ? "- (Please fill all the passenger details first)"
                          : ""
                      }`}</div>
                    </div>
                    <Stack className="Flight-faretype Flight-addon-wrap px-3  gap-lg-0 gap-3">
                      {(ssrBtnObj.onward.seat || ssrBtnObj.return.seat) &&
                        addOnSwitch.seat && (
                          <Stack
                            className="p-3 px-lg-0 Flight-addon-border align-items-start"
                            direction="horizontal"
                            gap={2}
                          >
                            <div className="d-flex align-items-start">
                              <div className="me-2 Flight-ssr-icon">
                                <Image
                                  src={renderImage(
                                    "search-result/addon-airplane.svg"
                                  )}
                                  alt="addon"
                                />
                              </div>

                              <div className="Flight-font14  text-capitalize Flight-font-semibold fchild-mb">
                                {travellerInfo[0]?.ssrSeatInfos?.length
                                  ? Object.keys(showSeatDetail).map(
                                      (trip, index) => (
                                        <React.Fragment key={index}>
                                          <span>{capitalize(trip)}</span>
                                          <p className="Flight-font12 Flight-txt-gray">
                                            {Object.keys(
                                              showSeatDetail[trip]
                                            ).map((deparr) => (
                                              <span>
                                                {deparr + " "}
                                                {`${showSeatDetail[trip][
                                                  deparr
                                                ].join(", ")} Added For ${
                                                  showSeatDetail[trip][deparr]
                                                    .length
                                                } Traveller `}
                                              </span>
                                            ))}
                                          </p>
                                        </React.Fragment>
                                      )
                                    )
                                  : "Need more legroom? Choose the seat you want"}
                              </div>
                            </div>
                            <div className=" ms-auto">
                              <Button
                                className="btn Flight-btn-purple Flight-sm-btn Flight-font13 rounded py-1"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                name="Select Seats"
                                disabled={addOnSwitch.seat ? false : true}
                                onClick={(e) => handleAddonModals(e)}
                              >
                                {travellerInfo[0]?.ssrSeatInfos?.length
                                  ? "Change Seat"
                                  : "Select"}
                              </Button>
                            </div>
                          </Stack>
                        )}
                      {(ssrBtnObj.onward.meal || ssrBtnObj.return.meal) &&
                        addOnSwitch.meal && (
                          <Stack
                            className="p-3 px-lg-0 Flight-addon-border align-items-start"
                            direction="horizontal"
                            gap={2}
                          >
                            <div className="d-flex align-items-start">
                              <div className="me-2 Flight-ssr-icon">
                                <Image
                                  src={renderImage(
                                    "search-result/addon-meals.svg"
                                  )}
                                  alt="meal"
                                />
                              </div>

                              <div className="Flight-font14 text-capitalize Flight-font-semibold">
                                {travellerInfo[0]?.ssrMealInfos?.length
                                  ? Object.keys(showMealDetail).map(
                                      (trip, index) => (
                                        <React.Fragment key={index}>
                                          {capitalize(trip)}
                                          <p className="Flight-font12 Flight-txt-gray mb-0">
                                            {Object.keys(
                                              showMealDetail[trip]
                                            ).map((deparr) => (
                                              <span>
                                                {deparr}
                                                {` ${showMealDetail[trip][deparr].length} Meals Selected `}
                                              </span>
                                            ))}
                                          </p>
                                        </React.Fragment>
                                      )
                                    )
                                  : "Add delicious meals"}
                              </div>
                            </div>
                            <div className="ms-auto">
                              <Button
                                name="Select Meals"
                                className="btn  Flight-btn-purple Flight-sm-btn Flight-font13 rounded py-1"
                                data-toggle="modal"
                                disabled={addOnSwitch.meal ? false : true}
                                onClick={(e) => handleAddonModals(e)}
                              >
                                {travellerInfo[0]?.ssrMealInfos?.length
                                  ? "Change Meal"
                                  : "Add"}
                              </Button>
                            </div>
                          </Stack>
                        )}
                      {(ssrBtnObj.onward.baggage || ssrBtnObj.return.baggage) &&
                        addOnSwitch.bag && (
                          <Stack
                            className="p-3 px-lg-0 Flight-addon-border align-items-start"
                            direction="horizontal"
                            gap={2}
                          >
                            <div className="d-flex align-items-start">
                              <div className="me-2 Flight-ssr-icon">
                                <Image
                                  width={20}
                                  src={renderImage(
                                    "search-result/addon-baggage.svg"
                                  )}
                                  alt="baggage"
                                />
                              </div>

                              <div className="Flight-font14  text-capitalize Flight-font-semibold">
                                {travellerInfo[0]?.ssrBaggageInfos?.length
                                  ? Object.keys(showBaggageDetail).map(
                                      (trip, index) => (
                                        <React.Fragment key={index}>
                                          {capitalize(trip)}
                                          <p className="Flight-font12 Flight-txt-gray mb-0">
                                            {Object.keys(
                                              showBaggageDetail[trip]
                                            ).map((deparr) => (
                                              <span>
                                                {deparr}
                                                {` ${showBaggageDetail[trip][deparr].length} Bags Selected `}
                                              </span>
                                            ))}
                                          </p>
                                        </React.Fragment>
                                      )
                                    )
                                  : `Carrying more luggage? Get extra check-in
                                       baggage`}
                              </div>
                            </div>
                            <div className="ms-auto">
                              <Button
                                name="Select Baggage"
                                className="btn Flight-btn-purple Flight-sm-btn Flight-font13 rounded py-1"
                                data-toggle="modal"
                                disabled={addOnSwitch.bag ? false : true}
                                data-target=".add-baggage-modal"
                                onClick={(e) => handleAddonModals(e)}
                              >
                                {travellerInfo[0]?.ssrBaggageInfos?.length
                                  ? "Change Baggage"
                                  : "Add"}
                              </Button>
                            </div>
                          </Stack>
                        )}
                    </Stack>
                  </div>
                )}
              </fieldset>
            )}
            <div className="Flight-shadow-box bg-white mb-lg-3 pt-lg-0 pt-2">
              <div className="px-3 py-2">
                <div className="Flight-font16 Flight-font-bold">
                  Contact Information
                </div>
                <div className="Flight-font13 Flight-txt-gray Flight-font-medium">
                  Your ticket and flights information will be sent here.
                </div>
              </div>
              <div className="Flight-faretype">
                <div className="Flight-home-padding pt-lg-3 pt-1">
                  <Row className="g-3 Flight-row">
                    <Col md="4" sm="6" className="pe-md-0">
                      <div className="form-group mb-0">
                        <EmailInput
                          value={email}
                          onChange={handleEmailChange}
                          id="emailadd"
                          className={
                            emailError
                              ? "form-control form-home Flight-form-control border-danger"
                              : "form-control form-home Flight-form-control"
                          }
                          placeholder="Email Address*"
                        />
                        {emailError && (
                          <div className="invalid-feedback d-block Flight-font12 Flight-font-medium">
                            {emailError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md="4" sm="6" className="pe-md-0">
                      <div className="form-group mb-0 Flight-telpicker">
                        <PhoneInput
                          dropdownClass="custom-dropdown mt-0 ml-5"
                          name="phoneInput"
                          className={
                            phoneNumError
                              ? "c-code-control border-danger"
                              : "c-code-control"
                          }
                          country={"in"}
                          enableSearch={true}
                          value={phoneNum}
                          onChange={handlePhoneNumChange}
                          placeholder="Phone Number*"
                          required
                        />
                        <div className="invalid-feedback d-block Flight-font12 Flight-font-medium">
                          {phoneNumError && (
                            <p className="mb-0">{phoneNumError}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="Flight-shadow-box bg-white py-3 mb-lg-3">
              <div className="px-3 py-2">
                <div className="Flight-custom-checkbox Flight-custom-control Flight-cursor-pointer">
                  <CheckboxInput
                    id="test"
                    onChange={() => setShowGst(!showGst)}
                    className="Flight-custom-control-input"
                    name="airlinename"
                    value={showGst}
                  />
                  <label
                    className="Flight-custom-control-label text-capitalize Flight-font16 Flight-font-bold"
                    htmlFor="test"
                  >
                    I have a GST number (Optional)
                  </label>
                </div>
              </div>
              {showGst ? (
                <>
                  <div className="Flight-faretype">
                    <div className="p-3">
                      <div className="Flight-font14 Flight-font-semibold">
                        Billing Profile
                      </div>
                    </div>
                    <div className="Flight-home-padding pt-0">
                      <Row className="g-md-2 g-3 Flight-row">
                        <Col md="4" sm="6">
                          <div className="form-group mb-0">
                            <TextInput
                              id="gstnumber"
                              value={gstInfo.gstNumber}
                              onChange={(e) => {
                                const gstNumber = e.currentTarget.value;
                                setGstInfo({
                                  ...gstInfo,
                                  gstNumber: gstNumber,
                                });

                                const gstRegex =
                                  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/; // Modify the regex pattern according to the GST format you want to validate

                                if (!gstRegex.test(gstNumber)) {
                                  setGstError("Invalid GST number");
                                } else {
                                  setGstError("");
                                }
                              }}
                              className="form-control form-home Flight-form-control"
                              placeholder="Company GST Number*"
                              required
                            />
                            {gstError && (
                              <div className="invalid-feedback d-block Flight-font12 Flight-font-medium">
                                {gstError}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col md="4" sm="6">
                          <div className="form-group mb-0">
                            <EmailInput
                              value={gstInfo.email}
                              onChange={(e) => {
                                const emailValue = e.currentTarget.value;
                                setGstInfo({
                                  ...gstInfo,
                                  email: emailValue,
                                });

                                if (validateEmail(emailValue)) {
                                  setGstEmailError("");
                                } else {
                                  setGstEmailError(
                                    "Please enter a valid email address."
                                  );
                                }
                              }}
                              id="companyemail"
                              className="form-control form-home Flight-form-control"
                              placeholder="Email Address*"
                            />

                            {gstEmailError && (
                              <div className="invalid-feedback d-block Flight-font12 Flight-font-medium">
                                {gstEmailError}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col md="4" sm="6">
                          <div className="form-group mb-0">
                            <TextInput
                              type="tel"
                              value={gstInfo.mobile}
                              onChange={(e) => {
                                setGstInfo({
                                  ...gstInfo,
                                  mobile: e.currentTarget.value,
                                });
                                if (
                                  validateGSTPhoneNumber(e.currentTarget.value)
                                ) {
                                  setGstPNError("");
                                } else {
                                  setGstPNError(
                                    "Please enter a valid mobile number."
                                  );
                                }
                              }}
                              id="phonenumber"
                              className="form-control form-home Flight-form-control"
                              placeholder="Mobile*"
                              required
                            />

                            {gstPNError && (
                              <div className="invalid-feedback d-block Flight-font12 Flight-font-medium">
                                {gstPNError}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col md="4" sm="6">
                          <div className="form-group mb-0">
                            <TextInput
                              type="Number"
                              value={gstInfo.registeredName}
                              onChange={(e) => {
                                setGstInfo({
                                  ...gstInfo,
                                  registeredName: e.currentTarget.value,
                                });
                              }}
                              id="companyname"
                              className="form-control form-home Flight-form-control"
                              placeholder="Company Name*"
                              required
                            />
                          </div>
                        </Col>
                        <Col md="4" sm="6">
                          <div className="form-group mb-0">
                            <TextInput
                              rvalue={gstInfo.address}
                              onChange={(e) => {
                                setGstInfo({
                                  ...gstInfo,
                                  address: e.currentTarget.value,
                                });
                              }}
                              id="companyaddress"
                              className="form-control form-home Flight-form-control"
                              placeholder="Company Address*"
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            <div className="d-none d-lg-flex align-items-center justify-content-center">
              <Button
                className="btn btn-lg btn-block Flight-btn-gradient Flight-btn-purple"
                disabled={
                  contactDeatsComplete && paxDeatsComplete && !duplicateName
                    ? false
                    : true
                }
                onClick={() => bookingHadler()}
              >
                Make Payment
              </Button>
            </div>
          </Col>
          <Col lg="3">
            <div className="Flight-static-div p-lg-0 p-3">
              <FareSummary
                data={fareSummary}
                setFinalGrandTotal={setFinalGrandTotal}
                convenienceFee={vendorCharges}
                mgtFee={mfTotal + mftTotal}
              />
              <Row className="pb-2">
                {contactDeatsComplete &&
                paxDeatsComplete &&
                !duplicateName ? null : (
                  <small className="text-danger text-center Flight-font-bold">
                    Please fill in all mandatory fields.
                  </small>
                )}
              </Row>
              <div className="text-center">
                <Button
                  onClick={() => bookingHadler()}
                  disabled={
                    contactDeatsComplete && paxDeatsComplete && !duplicateName
                      ? false
                      : true
                  }
                  className="btn btn-lg btn-block Flight-btn-purple Flight-btn-gradient w-100"
                >
                  {" "}
                  Make Payment
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Addon Modals */}
      {addOnModalContent && (
        <Modal
          className="Flight-modal"
          size="md"
          centered
          show={addOnModalContent !== null}
          onHide={() => setAddonsModalContent(null)}
        >
          <Modal.Header
            closeButton
            className="justify-content-center text-center absolute-close border-top-0 Flight-border-dashed"
          >
            <Modal.Title className="Flight-font16 Flight-font-bold">
              <Fragment>
                {addOnModalContent}
                <span className="d-block Flight-font12">
                  {`${
                    reviewData?.searchQuery?.routeInfos[0].fromCityOrAirport
                      .city
                  } - ${
                    reviewData?.searchQuery?.routeInfos[0].toCityOrAirport.city
                  } ${
                    reviewData?.searchQuery?.searchType === "ONEWAY"
                      ? " | " +
                        getDateByFormat(reviewData?.tripInfos[0]?.sI[0]?.dt)
                      : ""
                  } | ${string.string.transform.capitalizeEachWord(
                    reviewData?.searchQuery?.cabinClass.replace("_", " ")
                  )}`}
                </span>
              </Fragment>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-0 pb-2">
            {addOnModalContent === "Select Meals" && (
              <div className="Flight-addon-tabs">
                <Tabs
                  className={`${
                    reviewData?.searchQuery?.searchType === "ONEWAY" && "d-none"
                  }`}
                >
                  {reviewData?.searchQuery?.routeInfos?.map((i, j) => {
                    if (j === 0) {
                      if (!ssrBtnObj.onward.meal) return null;
                    } else {
                      if (!ssrBtnObj.return.meal) return null;
                    }
                    return (
                      <Tab
                        className="addon-top-tab"
                        key={"menu-tabs" + j}
                        eventKey={j.toString()}
                        title={
                          <Fragment>
                            <div>{`${i.fromCityOrAirport.code} - ${i.toCityOrAirport.code}`}</div>
                            <div className="Flight-font10 text-dark Flight-ln">
                              {getDateByFormat(
                                reviewData?.tripInfos[j].sI[0].dt
                              )}
                            </div>
                          </Fragment>
                        }
                      >
                        <Tabs
                          className={`${
                            reviewData.tripInfos[j].sI.length === 1 && "d-none"
                          }`}
                        >
                          {reviewData?.tripInfos[j]?.sI.map((k, l) => {
                            let title = `${k?.da?.code}-${k?.aa?.code}`;
                            return (
                              <Tab
                                key={"menu-subtab" + l}
                                eventKey={l.toString()}
                                title={title}
                              >
                                <MealMenu
                                  meals={
                                    k.ssrInfo.hasOwnProperty("MEAL")
                                      ? k.ssrInfo["MEAL"]
                                      : []
                                  }
                                  trip={title}
                                  type={j === 0 ? "onward" : "return"}
                                  segmentId={k?.id}
                                  travellerInfo={travellerInfo}
                                  setTravellerInfo={(a) => setTravellerInfo(a)}
                                  closeModal={(a) => setAddonsModalContent(a)}
                                />
                              </Tab>
                            );
                          })}
                        </Tabs>
                      </Tab>
                    );
                  })}
                </Tabs>
              </div>
            )}
            {addOnModalContent === "Select Seats" &&
              (!seatMapLoader ? (
                <div className="Flight-addon-tabs">
                  <Tabs
                    className={`${
                      reviewData?.searchQuery?.searchType === "ONEWAY" &&
                      "d-none"
                    }`}
                  >
                    {reviewData?.searchQuery?.routeInfos?.map((i, j) => {
                      if (j === 0) {
                        if (!ssrBtnObj.onward.seat) return null;
                      } else {
                        if (!ssrBtnObj.return.seat) return null;
                      }
                      return (
                        <Tab
                          className="addon-top-tab"
                          key={"seat-tab" + j}
                          eventKey={j.toString()}
                          title={
                            <Fragment>
                              <div>{`${i.fromCityOrAirport.code} - ${i.toCityOrAirport.code}`}</div>
                              <div className="Flight-font10 text-dark Flight-ln">
                                {getDateByFormat(
                                  reviewData?.tripInfos[j].sI[0].dt
                                )}
                              </div>
                            </Fragment>
                          }
                        >
                          <Tabs
                            className={`${
                              reviewData.tripInfos[j].sI.length === 1 &&
                              "d-none"
                            }`}
                          >
                            {reviewData?.tripInfos[j]?.sI.map((k, l) => {
                              let siId = k?.id;
                              let title = `${k?.da?.code}-${k?.aa?.code}`;
                              return (
                                <Tab
                                  key={j + "seat-subtab" + l}
                                  eventKey={l.toString()}
                                  title={title}
                                >
                                  <SeatLayout
                                    sData={
                                      seatMapData.tripSeatMap.tripSeat[siId]
                                    }
                                    trip={title}
                                    type={j === 0 ? "onward" : "return"}
                                    travellerInfo={travellerInfo}
                                    setTravellerInfo={(a) =>
                                      setTravellerInfo(a)
                                    }
                                    segmentId={siId}
                                    closeModal={(a) => setAddonsModalContent(a)}
                                  />
                                </Tab>
                              );
                            })}
                          </Tabs>
                        </Tab>
                      );
                    })}
                  </Tabs>
                </div>
              ) : (
                <>
                  <div className="Flight-loaderGIF">
                    <Image
                      src={renderImage("loader.gif")}
                      className="img-fluid"
                    />
                  </div>
                </>
              ))}
            {addOnModalContent === "Select Baggage" && (
              <div className="Flight-addon-tabs">
                <Tabs
                  className={`${
                    reviewData?.searchQuery?.searchType === "ONEWAY" && "d-none"
                  }`}
                >
                  {reviewData?.searchQuery?.routeInfos?.map((i, j) => {
                    if (j === 0) {
                      if (!ssrBtnObj.onward.baggage) return null;
                    } else {
                      if (!ssrBtnObj.return.baggage) return null;
                    }
                    return (
                      <Tab
                        className="addon-top-tab"
                        key={"bag-tab" + j}
                        eventKey={j.toString()}
                        title={
                          <Fragment>
                            <div>{`${i.fromCityOrAirport.code} - ${i.toCityOrAirport.code}`}</div>
                            <div className="Flight-font10 text-dark Flight-ln">
                              {getDateByFormat(
                                reviewData?.tripInfos[j].sI[0].dt
                              )}
                            </div>
                          </Fragment>
                        }
                      >
                        <Tabs className={`${"d-none"}`}>
                          {reviewData?.tripInfos[j]?.sI.map((k, l) => {
                            let title = `${k?.da?.code}-${i.toCityOrAirport.code}`;
                            return (
                              <Tab
                                key={"bag-subtab" + l}
                                eventKey={l.toString()}
                                title={title}
                              >
                                <ViewBaggage
                                  bags={
                                    k.ssrInfo.hasOwnProperty("BAGGAGE")
                                      ? k.ssrInfo["BAGGAGE"]
                                      : []
                                  }
                                  trip={title}
                                  type={j === 0 ? "onward" : "return"}
                                  segmentId={k?.id}
                                  travellerInfo={travellerInfo}
                                  setTravellerInfo={(a) => setTravellerInfo(a)}
                                  closeModal={(a) => setAddonsModalContent(a)}
                                />
                              </Tab>
                            );
                          })}
                        </Tabs>
                      </Tab>
                    );
                  })}
                </Tabs>
              </div>
            )}
          </Modal.Body>
        </Modal>
      )}

      <Modal
        className="Flight-modal"
        show={showModal}
        centered
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Technical Issue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!taFlag
            ? "Due to some technical issue, we could not process your payment. Please try again."
            : "We regret to inform you that your payment could not be processed at this time due to unforeseen technical issues. Please try again later to complete your transaction."}
        </Modal.Body>
        <Modal.Footer>
          {!taFlag ? (
            <Button
              className="btn Flight-btn-purple Flight-font13 Flight-font-semibold"
              variant="primary"
              onClick={() => {
                setShowModal(false);
                setTAFlag(true);
                bookingHadler();
              }}
            >
              Try Again
            </Button>
          ) : (
            <Button
              className="btn Flight-btn-purple Flight-font13 Flight-font-semibold"
              variant="primary"
              onClick={() => {
                history.push("/flights");
              }}
            >
              Search Again
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal centered show={loginModal} size="md" dialogClassName="modal-400w">
        <Modal.Header
          className="justify-content-end border-bottom-0 pb-0"
          closeButton
        ></Modal.Header>
        <Modal.Body className="px-md-5 px-4">
          <LoginModal />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default FlightDetail;
