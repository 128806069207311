import React from 'react'
import Pagebg from '../../assets/images/landingpage/lnd_backgroundv2.jpg'
import Pagebgmob from '../../assets/images/landingpage/lnd_background_mob.jpg'

function Landing() {
  return (
    <div className='ldg-bg h-100 overflow-hidden d-flex  p-3 p-lg-0'>
        <img src={Pagebg} style={{width:'1200px',objectFit:'contain', height:'inherit'}} className=' d-none d-lg-block m-auto'/>
        <img src={Pagebgmob} className='w-100 d-block d-lg-none m-auto h-100 object-fit-contain'/>

    </div>
  )
}

export default Landing