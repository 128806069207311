import React, { Fragment } from "react";
import { Stack, Table, Row, Col } from "react-bootstrap";
import string from "../../../commons/util/UtilManager";
import {
  formatTimeToHour,
  getDateByFormat,
  getTimeFromString,
} from "../../../commons/util/dateTimeUtil";
import { renderImage } from "../../../commons/util/flightUtil";
import Image from "../../../commons/artefacts/elements/input/image";
import numberToIndCurr from "../../../commons/util/priceUtil";

const BookingsViewDetailModal = (props) => {
  const {
    bookingDetails: {
      tripInfos,

      paxInfo,
      cabinClass,

      convFee,
    },
  } = props?.data;

  let passenger = [
    {
      ADULT: paxInfo.filter((pax) => pax.type === "ADULT").length,
      price: 0,
    },
    {
      CHILD: paxInfo.filter((pax) => pax.type === "CHILD").length,
      price: 0,
    },
    {
      INFANT: paxInfo.filter((pax) => pax.type === "INFANT").length,
      price: 0,
    },
  ];

  tripInfos?.forEach((x) => {
    x.totalPriceList.forEach((y) => {
      let adultCount = passenger[0].ADULT;
      let adultPrice = y.fd?.hasOwnProperty("ADULT")
        ? y.fd?.ADULT?.fC
          ? y.fd?.ADULT?.fC.BF
          : 0
        : 0;
      let adultTP = eval(adultCount * adultPrice);
      passenger[0].price = eval(passenger[0].price + adultTP);
      let childCount = passenger[1].CHILD;
      let childPrice = y.fd?.CHILD
        ? y.fd?.CHILD?.fC
          ? y.fd?.CHILD?.fC?.BF
          : 0
        : 0;
      let childTP = childCount * childPrice;
      passenger[1].price = passenger[1].price + childTP;

      let infantCount = passenger[2].INFANT;
      let infantPrice = y.fd?.INFANT
        ? y.fd?.INFANT?.fC
          ? y.fd?.INFANT?.fC.BF
          : 0
        : 0;
      let infantTP = infantCount * infantPrice;
      passenger[2].price = passenger[2].price + infantTP;
    });
  });

  //Calculate Total of SSR OR ADDons :
  let totalSsrSeatAmount = 0;
  let totalSsrMealAmount = 0;
  let totalSsrBaggageAmount = 0;

  paxInfo.forEach((passenger) => {
    passenger.ssrSeatInfos.forEach((seatInfo) => {
      totalSsrSeatAmount += seatInfo.amount;
    });

    passenger.ssrMealInfos.forEach((mealInfo) => {
      totalSsrMealAmount += mealInfo.amount;
    });

    passenger.ssrBaggageInfos.forEach((baggageInfo) => {
      totalSsrBaggageAmount += baggageInfo.amount;
    });
  });

  // Creating a deep copy of the bookingDetails object
  let tempBD = JSON.parse(JSON.stringify(props?.data?.bookingDetails));

  // Distributing the ssrValues to each sI (sub-item) in the tripInfos array
  tempBD.tripInfos.map((x, i) => {
    let allSiKeys = [];

    // Extracting all sI keys from the sI array in each tripInfo
    x.sI.forEach((y) => {
      allSiKeys.push(y.id);
    });

    // Creating a deep copy of the paxInfo array
    let paxCopy = tempBD.paxInfo.map((y) => ({ ...y }));

    // Updating ssrSeatInfos, ssrMealInfos, and ssrBaggageInfos for each passenger in sI[0]
    x.sI[0].paxInfo = paxCopy.map((y) => {
      // Filtering the ssrSeatInfos array to include only the ones with keys present in allSiKeys
      let tempSeatInfos = y.ssrSeatInfos.filter((seatSsr) =>
        allSiKeys.includes(seatSsr.key)
      );
      y.ssrSeatInfos = tempSeatInfos;

      // Filtering the ssrMealInfos array to include only the ones with keys present in allSiKeys
      let tempMealInfos = y.ssrMealInfos.filter((mealSsr) =>
        allSiKeys.includes(mealSsr.key)
      );
      y.ssrMealInfos = tempMealInfos;

      // Filtering the ssrBaggageInfos array to include only the ones with keys present in allSiKeys
      let tempBaggageInfos = y.ssrBaggageInfos.filter((bagSsr) =>
        allSiKeys.includes(bagSsr.key)
      );
      y.ssrBaggageInfos = tempBaggageInfos;

      return y;
    });

    return x;
  });
  const {
    afC: { TAF },
  } = tempBD?.totalFareDetail;
  // Remove "MF" and "MFT" keys if they exist in TAF
  if (TAF.hasOwnProperty("MF")) {
    delete TAF.MF;
  }

  if (TAF.hasOwnProperty("MFT")) {
    delete TAF.MFT;
  }
  if (TAF.hasOwnProperty("CF")) {
    delete TAF.CF;
  }
  const sumTAF = Object.values(TAF).reduce((acc, curr) => acc + curr, 0);
  return (
    <Fragment>
      {tempBD?.tripInfos.length > 0
        ? tempBD?.tripInfos.map((trips, i) => {
            const duration = trips?.sI.reduce(
              (acc, curr) => acc + curr.duration,
              0
            );
            const layOverTime = trips?.sI.reduce((acc, curr) => {
              if ("cT" in curr) {
                acc += curr.cT;
              }
              return acc;
            }, 0);

            return (
              <section>
                <div className="px-3">
                  <h3 className="Flight-font20 Flight-font-semibold mb-0">
                    {`${trips?.sI[0]?.da?.city}-${
                      trips?.sI[trips?.sI?.length - 1]?.aa?.city
                    }`}
                  </h3>
                  <span className="time-total rounded px-3 py-1 my-3 d-inline-block">
                    {getDateByFormat(trips?.sI[0]?.dt)}
                  </span>
                  <Stack direction="horizontal" gap={3}>
                    <div>
                      <Image
                        className="me-2"
                        src={renderImage("icon-time.svg")}
                      />

                      {formatTimeToHour(duration + layOverTime)}
                    </div>
                    <div className="stop-li">
                      {trips?.sI.length === 1
                        ? "Non-stop"
                        : `${trips?.sI.length - 1} Stops`}
                    </div>
                  </Stack>
                </div>
                {trips?.sI.map((item) => {
                  return (
                    <>
                      <div className="fl-detail px-3 py-2 my-3">
                        <div className="Flight-logo-sec text-left d-flex align-items-center">
                          <div>
                            <img
                              alt=""
                              src={renderImage(item?.fD?.aI?.code)}
                              className="img-fluid blur-up lazyloaded"
                            />
                          </div>
                          <div className="ms-2 ph-in">
                            <span className="title">{item?.fD?.aI?.name}</span>
                            <span className="Flight-font12 Flight-txt-gray Flight-font-semibold Flight-fnum">
                              {`${item?.fD?.aI?.code}-${item?.fD?.fN}`}{" "}
                            </span>
                            <span className="Flight-font12 Flight-txt-gray d-md-block d-none Flight-font-semibold fcls">
                              {string.string.transform
                                .capitalizeEachWord(cabinClass)
                                ?.replace("_", " ")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="timeline px-3">
                        <div className="tl-depcity tl-depcity1">
                          <span className="Flight-font18 Flight-font-semibold">
                            {`${getTimeFromString(item?.dt)} ${item?.da?.city}`}
                          </span>
                          <div className="Flight-font-semibold text-gray2">
                            {item?.da?.name} {item?.da?.terminal}{" "}
                            {item?.da?.city}, {item?.da?.countryCode}
                          </div>
                          {item?.duration && (
                            <div className="Flight-font-semibold text-gray2 bt-time">
                              <Image
                                className="me-2"
                                src={renderImage("icon-time.svg")}
                                alt=""
                              />

                              {formatTimeToHour(item?.duration)}
                            </div>
                          )}
                        </div>
                        <div className="tl-depcity">
                          <span className="Flight-font18 Flight-font-semibold">
                            {" "}
                            {`${getTimeFromString(item?.at)} ${item?.aa?.city}`}
                          </span>
                          <div className="Flight-font-semibold text-gray2">
                            {item?.aa?.name} {item?.aa?.terminal}{" "}
                            {item?.aa?.city}, {item?.aa?.countryCode}
                          </div>
                        </div>
                        {item?.cT && (
                          <div className="total-time mt-2 Flight-font-semibold text-gray2">
                            <Image
                              className="me-1"
                              src={renderImage("icon-time.svg")}
                              alt=""
                            />
                            {formatTimeToHour(item?.cT)} connecting in airport
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
              </section>
            );
          })
        : null}

      <div className="fl-detail py-3 my-2 px-3">
        <h2 className="text-secondary Flight-font14 Flight-font-semibold mb-1">
          Booking Summary
        </h2>
        <div className="table-responsive">
          <Table className="table table-borderless mb-0 Flight-font14 Flight-font-semibold">
            <tbody>
              <tr>
                <td className="px-0 py-1">Base Fare</td>
                <td className="text-end px-0 py-1">
                  {numberToIndCurr(
                    passenger[0]?.price +
                      passenger[1]?.price +
                      passenger[2]?.price
                  )}
                </td>
              </tr>
              <tr>
                <td className="px-0 py-1">Total Taxes</td>
                <td className="text-end px-0 py-1">
                  {numberToIndCurr(sumTAF)}
                </td>
              </tr>
              <tr>
                <td className="px-0 py-1">Convenience Fee</td>
                <td className="text-end px-0 py-1">
                  {numberToIndCurr(convFee)}
                </td>
              </tr>
              <tr>
                <td className="px-0 py-1">Discount</td>
                <td className="text-end px-0 py-1">{numberToIndCurr(0)}</td>
              </tr>
              <tr>
                <td className="px-0 py-1">Seat Charges</td>
                <td className="text-end px-0 py-1">
                  {numberToIndCurr(totalSsrSeatAmount)}
                </td>
              </tr>
              <tr>
                <td className="px-0 py-1">Meal Charges</td>
                <td className="text-end px-0 py-1">
                  {numberToIndCurr(totalSsrMealAmount)}
                </td>
              </tr>
              <tr>
                <td className="px-0 py-1">Baggage Charges</td>
                <td className="text-end px-0 py-1">
                  {numberToIndCurr(totalSsrBaggageAmount)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="px-3">
        <Row className="Flight-font16 Flight-font-bold">
          <Col>Total</Col>
          <Col className="text-end">
            {numberToIndCurr(
              passenger[0]?.price +
                passenger[1]?.price +
                passenger[2]?.price +
                convFee +
                sumTAF +
                totalSsrSeatAmount +
                totalSsrMealAmount +
                totalSsrBaggageAmount
            )}
          </Col>
        </Row>
      </div>
      {/* <div className="fl-detail px-3 py-3 my-2">
        <h2 className="text-secondary Flight-font14 Flight-font-semibold mb-1">
          Cancellation Details
        </h2>
        <div className="table-responsive">
          <Table className="table table-borderless mb-0 Flight-font14 Flight-font-semibold">
            <tbody>
              <tr>
                <td className="px-0 py-1">Cancellation Fee</td>
                <td className="text-end px-0 py-1">₹3000</td>
              </tr>
              <tr>
                <td className="px-0 py-1">Convenience Fee</td>
                <td className="text-end px-0 py-1">₹0</td>
              </tr>
              <tr>
                <td className="px-0 py-1">Date change Fee</td>
                <td className="text-end px-0 py-1">₹0</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="px-3">
        <Row className="Flight-font-semibold">
          <Col>Payment Method</Col>
          <Col className="text-end">Net Banking</Col>
        </Row>
            </div>*/}
    </Fragment>
  );
};

export default BookingsViewDetailModal;
