let init_filter = {
  stops: [false, false, false],
  departureTime: "",
  arrivalTime: "",
  priceRange: { min: 0, max: 150000 },
  aircraft: new Set(),
};

const filterReducer = (state = init_filter, action) => {
  const { stops } = state;
  const { departureTime, arrivalTime, index, range, aircraftName } =
    action.payload;

  switch (action.type) {
    case "StopUpdated":
      const cloneStop = [...stops];
      cloneStop[index] = !stops[index];
      return { ...state, stops: cloneStop };
    case "DepartureUpdated":
      return { ...state, departureTime: departureTime };
    case "ArrivalUpdated":
      return { ...state, arrivalTime: arrivalTime };
    case "PriceUpdated":
      return { ...state, priceRange: range };
    case "AircraftUpdated":
      let updateAircart = new Set(state.aircraft);

      updateAircart.has(aircraftName)
        ? updateAircart.delete(aircraftName)
        : updateAircart.add(aircraftName);

      return { ...state, aircraft: updateAircart };
    case "RangeUpdated":
      return { ...state, priceRange: range };
    case "ResetState":
      return { ...init_filter, priceRange: range };
    default:
      return state;
  }
};

export { init_filter, filterReducer };
