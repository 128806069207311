import React from "react";
import styled from "styled-components";

const Select = styled.select``;

const Option = styled.option``;

function SelectionOption({ options, onChange, ...rest }) {
  const { className } = rest;
  return (
    <Select onChange={onChange} className={className}>
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
}

export default SelectionOption;
