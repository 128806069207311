/**
 * @param {string} source 
 * @returns 
 */
export const renderImage = (source) => {
    if (source.includes('.')) {
      return require(`../../assets/images/home/${source}`);
    }
  
    const fileExtensions = ['svg', 'png', 'jpg'];
    for (const extension of fileExtensions) {
      try {
        return require(`../../assets/images/home/airlinelogo/${source}.${extension}`);
      } catch (error) {}
    }
    const defaultImageName = 'airlinelogo/Airlineplaceholder';
    return require(`../../assets/images/home/${defaultImageName}.svg`).toString();
  }