import React, { useState, useEffect, useReducer, createContext } from "react";
import { addDays, addMonths } from "date-fns";
import { init_passengerState } from "../../web/reducers/passengers";
import { init_filter, filterReducer } from "../../web/reducers/filters";
import { searchDataReducer } from "../../web/reducers/searchData";
import {
  INITIAL_ARRIVAL_PLACE,
  INITIAL_DEPART_PLACE,
  INITIAL_FARE_TYPE,
  INITIAL_FLIGHT_CLASS,
  INITIAL_SEARCH_DATA,
} from "../../constants";





export const DataContext = createContext();


const DataContextProvider = ({
  children,
  airportData: airportsData,
  fareData: faresData,
  cabinData: cabinsData,
  convenienceFee: convenienceFee,
}) => {
  const [onFilterState, onFilterDispatch] = useReducer(
    filterReducer,
    init_filter
  );
  const [reFilterState, reFilterDispatch] = useReducer(
    filterReducer,
    init_filter
  );

  const [sortState, setSortState] = useState({
    onward: { path: "TF", order: "asc" },
    return: { path: "TF", order: "asc" },
  });

  const [searchData, searchDataDispatch] = useReducer(
    searchDataReducer,
    INITIAL_SEARCH_DATA
  );

  const handleDates = (storedData) => {
    let data = JSON.parse(storedData);

    let today = new Date();
    let endDate = data?.endDate ? new Date(data?.endDate) : data?.endDate;
    let startDate = data?.startDate
      ? new Date(data?.startDate)
      : data?.startDate;

    if (startDate && today > startDate) {
      data["startDate"] = today;
    }

    if (endDate && startDate >= endDate) {
      data["endDate"] = addDays(startDate, 2);
    }
    return data;
  };

  const [loader, setLoader] = useState(true);
  const [formState, setFormState] = useState(() => {
    const storedState =
      localStorage !== undefined && localStorage !== null
        ? localStorage?.getItem("formState")
        : null;

    return storedState
      ? handleDates(storedState)
      : {
          departPlace: INITIAL_DEPART_PLACE,
          arrivalPlace: INITIAL_ARRIVAL_PLACE,
          startDate: new Date().toISOString(),
          passengers: init_passengerState,
          endDate: null,
          fareType: INITIAL_FARE_TYPE,
          flightClass: INITIAL_FLIGHT_CLASS,
        };
  });
  const [showFareDetail, setShowFareDetail] = useState("");

  const [selectedOnward, setSelectedOnward] = useState([]);
  const [selectedReturn, setSelectedReturn] = useState([]);

  const [airportData, setAirportData] = useState(airportsData);
  const [fareData, setFareData] = useState(faresData);
  const [cabinData, setCabinData] = useState(cabinsData);

  const [journeyType, setJourneyType] = useState({
    type: "",
    journey: "",
  });
const [uid,setUID]=useState('')
  useEffect(() => {
    setAirportData(airportsData);
    setFareData(faresData);
    setCabinData(cabinsData);

    if (localStorage !== undefined && localStorage !== null) {
      localStorage.setItem("formState", JSON.stringify(formState));
    }
      }, [airportsData, faresData, cabinsData, formState]);

     
   

  return (
    <DataContext.Provider
      value={{
        selectedOnward,
        setSelectedOnward,
        selectedReturn,
        setSelectedReturn,
        searchData,
        searchDataDispatch,
        onFilterState,
        onFilterDispatch,
        reFilterState,
        reFilterDispatch,
        sortState,
        setSortState,
        formState,
        setFormState,
        loader,
        setLoader,
        airportData,
        fareData,
        cabinData,
        convenienceFee,
        setJourneyType,
        journeyType,
        showFareDetail,
        setShowFareDetail,
        uid,
        setUID,
        
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContextProvider;
